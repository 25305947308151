import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { AuthGet, AuthPost, AuthPut, Put } from "../../common_var/httpService";
import { useStateValue } from "../../Context/StateProvider";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

function Tenor() {
  const [initialState] = useStateValue();
  const token = sessionStorage.getItem("token");
  const [data, setdata] = useState([]);
  const [show, setShow] = useState(false);

  const [editedRowId, setEditedRowId] = useState(null);
  const [editedValue, setEditedValue] = useState([]);
  const [deleteRowId, setDeleteRowId] = useState(null);

  const handleClose = () => {
    setShow(false);
  };
  const handleOpen = () => {
    setShow(true);
  };

  const handleSelectEdit = (val) => {
    handleOpen();
  };

  useEffect(() => {
    if (initialState?.settingid !== "") {
      gettermnameList();
    }
  }, [initialState]);

  const formik = useFormik({
    initialValues: {
      term_description: "",
      settingId: initialState?.settingid?.setting_id,
    },
    validationSchema: Yup.object({
      term_description: Yup.string().required("*Term Name is required"),
    }),
    onSubmit: async (values) => {
      let sendData = {
        term_description: values.term_description,
        settingId: initialState?.settingid,
        version_id:initialState?.version_id,
        pm_id: initialState?.pm_id
      };
      await AuthPost(`api/term-model/add-term`, sendData)
        .then((res) => {
          console.log("res::: ", res);
          if (res?.status === 200 || res?.status === 201) {
            formik.resetForm();
            toast.success(res.message, { duration: 4000 });
            gettermnameList();
          } else {
            toast.error(res.message, { duration: 4000 });
          }
        })
        .catch((err) => {
          console.log("Error: ", err);
          toast.error(err.message, { duration: 4000 });
        });
    },
  });

  const formikedit = useFormik({
    initialValues: {
      termdescription: editedValue.termdescription,
    },
    // validationSchema: validationSchema,
    onSubmit: async (editedRowId) => {
      let sendData = {
        term_description: editedValue,
      };
      console.log(editedValue);
      await AuthPost(`api/term-model/add-term/${editedRowId}`, sendData)
        .then((res) => {
          if (res?.status === 200 || res?.status === 201) {
            formikedit.setValues(res?.editedValue);
            toast.success(res.message, { duration: 4000 });
            setEditedRowId(null);
            gettermnameList();
          } else {
            toast.error(res.message, { duration: 4000 });
          }
        })
        .catch((err) => {
          console.log("Error: ", err);
          toast.error(err.message, { duration: 4000 });
        });
    },
  });

  const gettermnameList = async () => {
    await AuthGet(`api/term-model/get-all/${initialState?.settingid}`, token)
      .then((res) => {
        if (res?.status === 200) {
          setdata(res?.data);
        }
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  const gradeDelete = async () => {
    await AuthPut(`api/term-model/update-inactive/${deleteRowId}`, {
      active: false,
      settingId: initialState?.settingid,
    })
      .then((res) => {
        if (res?.status === 200 || res?.status === 201) {
          gettermnameList();
          toast.success(res.message, { duration: 4000 });
          handleClose();
        } else {
          toast.error(res.message);
          handleClose();
        }
      })
      .catch((err) => {
        toast.error(err.message);
        handleClose();
      });
  };

  return (
    <div className=" ">
      <div>
        <div className="mb20 whiteBg pb18">
          <form>
            <div className=" ">
              <div className="row align-items-center">
                <div className="col-md-2 ">
                  {" "}
                  <label for="ContactName">Term Name &nbsp;</label>
                </div>
                <div className="col-md-2 ">
                  <input
                    name="term_description"
                    placeholder="Enter Term Name"
                    formControlName="term_description"
                    id="term_description"
                    onChange={(e) => {
                      formik.setFieldValue(
                        "term_description",
                        e.target.value
                          .replace(/[^a-zA-Z0-9-\s]/g, "")
                          .replace(/^0+/g, "")
                          .replace(/\.(\d{0,2}).*/g, ".$1")
                      );
                    }}
                    // onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.term_description}
                    className="form-control"
                  />{" "}
                  {formik.touched.term_description &&
                  formik.errors.term_description ? (
                    <p className="error_text text-danger">
                      {formik.errors.term_description}
                    </p>
                  ) : null}
                </div>
                <div className="col-md-2 ">
                  <button
                    className="btn btn-success  "
                    type="submit"
                    onClick={formik.handleSubmit}
                  >
                    <div className=" "> ADD </div>
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div className="row">
          <div className="col-12">
            <form name="form">
              <div className="tableResponsive">
                <table className="table mainTable">
                  <thead>
                    <tr>
                      <th style={{ width: "10%" }}>S.No</th>
                      <th style={{ width: "70%" }}>Term Name </th>
                      <th style={{ width: "20%" }}>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.length > 0 ? (
                      data?.map((data, index) => (
                        <tr key={data.id}>
                          <td scope="row">{index + 1}</td>

                          <td>
                            {editedRowId === data.id ? (
                              <input
                                type="text"
                                name="term_description"
                                className="form-control"
                                placeholder="term_description"
                                value={editedValue}
                                onChange={(e) => setEditedValue(e.target.value)}
                              />
                            ) : (
                              <span>{data.term_description}</span>
                            )}
                          </td>

                          <td>
                            {editedRowId === data.id ? (
                              <div>
                                <button
                                  className="btn btn-primary"
                                  type="button"
                                  style={{
                                    marginRight: "10px",
                                  }}
                                  onClick={() => {
                                    formikedit.handleSubmit();
                                  }}
                                >
                                  Save
                                </button>
                                <button
                                  className="btn btn-primary"
                                  type="button"
                                  onClick={() => {
                                    setEditedRowId(null);
                                  }}
                                >
                                  Cancel
                                </button>
                              </div>
                            ) : (
                              <div>
                                {/* <button
                              className="btn btn-outline-primary"
                              type="button"
                              style={{
                                marginRight: "10px",
                              }}
                              onClick={() => {
                                setEditedRowId(data.id);
                                setEditedValue(data.term_description);
                                formikedit.setValues(data.term_description);
                              }}
                            >
                              <i class="fa fa-pencil-square-o"></i>
                            </button> */}

                                <button
                                  disabled
                                  className="btn btn-outline-danger"
                                  type="button"
                                  // onClick={() => handleSelectEdit()}
                                  onClick={() => {
                                    setShow(true);
                                    setDeleteRowId(data.id);
                                  }}
                                >
                                  <i class="fa fa-trash"></i>
                                </button>
                              </div>
                            )}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <td colSpan="7" className="nodatarow">
                        No Terms Model Available for the Selected Provider
                      </td>
                    )}
                  </tbody>
                </table>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Modal
        show={show}
        onHide={() => {
          handleClose();
          formik.resetForm();
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete Term Name</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete term Name?</p>

          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                handleClose();
                formik.resetForm();
              }}
            >
              No
            </Button>
            <Button type="button" onClick={() => gradeDelete()}>
              Yes
            </Button>
          </Modal.Footer>
          {/* </form> */}
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default Tenor;
