import React from "react";
import error from "../../assets/images/unauthorised_error.png";

export const Unauthorized = () => {
  return (
    <div className="displayFlex justifycontencenter">
      <div className="textAlignCenter">
        <img src={error} className="error_pic " />
        <div className="textAlignCenter">
          <h1 className="fs60px">401 !</h1>
          <h3>You are not authorized to view this page</h3>
        </div>
      </div>
    </div>
  );
};
