import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { AuthGet, AuthPost, AuthPut, Put } from "../../common_var/httpService";
import { useStateValue } from "../../Context/StateProvider";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

export const Grade = () => {
  const [initialState] = useStateValue();
  const token = sessionStorage.getItem("token");
  const [data, setdata] = useState([]);
  const [show, setShow] = useState(false);
  const [deleteRowId, setDeleteRowId] = useState(null);
  const [editedRowId, setEditedRowId] = useState(null);
  const [editedValue, setEditedValue] = useState([]);
  console.log('editedValue::: ', editedValue);

  console.log('initialState::',initialState);


  const handleClose = () => {
    setShow(false);
  };
  const handleOpen = () => {
    setShow(true);
  };

  const handleSelectEdit = (val) => {
    handleOpen();
  };
  useEffect(() => {
    if (initialState?.settingid !== "") {
      getGradeList();
    }
  }, [initialState]);

  const formik = useFormik({
    initialValues: {
      description: "",
      settingId: initialState?.settingid?.setting_id,
    },
    validationSchema: Yup.object({
      description: Yup.string().required("*Grade name is required"),
    }),
    // validationSchema: validationSchema,
    onSubmit: async (values) => {
      let sendData = {
        description: values.description,
        settingId: initialState?.settingid,
        version_id: initialState?.version_id,
        pm_id: initialState?.pm_id
      };
      await AuthPost(`api/grade/add-grade`, sendData)
        .then((res) => {
          if (res?.status === 200 || res?.status === 201) {
            formik.resetForm();
            toast.success("Grade added Successfully", { duration: 4000 });
            getGradeList();
          } else {
            toast.error(res.message, { duration: 4000 });
          }
        })
        .catch((err) => {
          console.log("Error: ", err);
          toast.error(err.message, { duration: 4000 });
        });
    },
  });

  const gradeValidations = Yup.object({
    description: Yup.string().required("*Grade is required"),
    // offerId: Yup.string().required("*Offer name is required"),
  });

  const formikedit = useFormik({
    initialValues: {
      description: editedValue.description,
    },
    validationSchema: Yup.object({
      description: Yup.string().required("*Grade Name is required"),
    }),
    onSubmit: async (values) => {
      let sendData = {
        description: editedValue,
        settingId: initialState?.settingid,
      };
      await AuthPut(`api/grade/update-grade/${editedRowId}`, sendData)
        .then((res) => {
          if (res?.status === 200 || res?.status === 201) {
            formikedit.setValues(res?.editedValue);
            toast.success("Grade updated Successfully", { duration: 4000 });
            setEditedRowId(null);
            getGradeList();
            formikedit.resetForm()
          } else {
            toast.error(res.message, { duration: 4000 });
          }
        })
        .catch((err) => {
          console.log("Error: ", err);
          toast.error(err.message, { duration: 4000 });
        });
    },
  });
  const getGradeList = async () => {
    await AuthGet(`api/grade/get-all/${initialState?.settingid}`, token)
      .then((res) => {
        if (res?.status === 200) {
          setdata(res?.data);
        }
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  const gradeDelete = async () => {
    await AuthPut(`api/grade/grade-inactive/${deleteRowId}`, {
      active: false,
      settingId: initialState?.settingid,
    })
      .then((res) => {
        if (res?.status === 200 || res?.status === 201) {
          getGradeList();
          handleClose();
          toast.success("Grade deleted successfully", { duration: 4000 });
        } else {
          toast.error(res.message);
          handleClose();
        }
      })
      .catch((err) => {
        toast.error(err.message);
        handleClose();
      });
  };

  return (
    <div className=" ">
      <div>
        <div className="mb20 whiteBg pb18">
          <form>
            <div className=" ">
              <div className="row align-items-center">
                <div className="col-md-2 ">
                  {" "}
                  <label for="ContactName">Grade &nbsp;</label>
                </div>
                <div className="col-md-2 mobSpace_mb12">
                  <input
                    name="description"
                    placeholder="Enter Grade"
                    formControlName="description"
                    id="description"
                    onChange={(e) => {
                      formik.setFieldValue(
                        "description",
                        e.target.value
                          .replace(/[^a-zA-Z0-9-\s]/g, "")
                          .replace(/^0+/g, "")
                          .replace(/\.(\d{0,2}).*/g, ".$1")
                      );
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.description}
                    className="form-control"
                  />
                  {formik.touched.description && formik.errors.description ? (
                    <p className="error_text text-danger">
                      {formik.errors.description}
                    </p>
                  ) : null}
                </div>
                <div className="col-md-2 ">
                  <button
                    className="btn btn-success  "
                    type="submit"
                    onClick={formik.handleSubmit}
                    // disabled={formik.values.description === ""}
                  >
                    <div className=" "> ADD </div>
                  </button>{" "}
                </div>
              </div>
            </div>
          </form>
        </div>
        <div className="row">
          <div className="col-12">
            <form name="form">
              <div className="tableResponsive">
                <table className="table mainTable">
                  <thead>
                    <tr>
                      <th style={{ width: "10%" }}>S.No</th>
                      <th style={{ width: "70%" }}>Grade</th>
                      <th style={{ width: "20%" }}>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.length > 0 ? (
                      data?.map((data, index) => (
                        <tr key={data.id}>
                          <td scope="row">{index + 1}</td>
                          <td>
                            {editedRowId === data.id ? (
                              <form onSubmit={formikedit.handleSubmit}>
                                <div>
                                  <input
                                    type="text"
                                    name="description"
                                    className="form-control"
                                    placeholder="Grade"
                                    value={editedValue}
                                    onChange={(e) => {
                                      setEditedValue(e.target.value);
                                      formikedit.setFieldValue(
                                        "description",
                                        e.target.value
                                      );
                                    }}
                                  />
                                  {formikedit.touched.description &&
                                    formikedit.errors.description && (
                                      <span className="error_text">
                                        {formikedit.errors.description}
                                      </span>
                                    )}
                                </div>
                              </form>
                            ) : (
                              <span>{data.description}</span>
                            )}
                          </td>

                          <td>
                            {editedRowId === data.id ? (
                              <div>
                                <button
                                  className="btn btn-primary"
                                  type="button"
                                  style={{
                                    marginRight: "10px",
                                  }}
                                  onClick={() => {
                                    formikedit.handleSubmit();
                                  }}
                                >
                                  Save
                                </button>
                                <button
                                  className="btn btn-primary"
                                  type="button"
                                  onClick={() => {
                                    setEditedRowId(null);
                                    formikedit.resetForm()
                                  }}
                                >
                                  Cancel
                                </button>
                              </div>
                            ) : (
                              <div>
                                <button
                                  className="btn btn-outline-primary"
                                  type="button"
                                  disabled
                                  style={{
                                    marginRight: "10px",
                                  }}
                                  onClick={() => {
                                    setEditedRowId(data.id);
                                    setEditedValue(data.description);
                                    formikedit.setFieldValue("description",data.description);
                                  }}
                                >
                                  <i class="fa fa-pencil-square-o"></i>
                                </button>

                                <button
                                  className="btn btn-outline-danger"
                                  type="button"
                                  disabled
                                  onClick={() => {
                                    setShow(true);
                                    setDeleteRowId(data.id);
                                  }}
                                >
                                  <i class="fa fa-trash"></i>
                                </button>
                              </div>
                            )}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <td colSpan="3" className="nodatarow">
                        No Grades Available for the Selected Provider
                      </td>
                    )}
                  </tbody>
                </table>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Modal
        show={show}
        onHide={() => {
          handleClose();
          formik.resetForm();
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete Grade</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete grade?</p>

          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                handleClose();
                formik.resetForm();
              }}
            >
              No
            </Button>
            <Button type="button" onClick={() => gradeDelete()}>
              Yes
            </Button>
          </Modal.Footer>
          {/* </form> */}
        </Modal.Body>
      </Modal>
    </div>
  );
};
