// import logo from '../../Assets/logo.png'
import loader from '../../assets/images/de-loader.gif'

export const Loader = () => {
  return (
    <div className='loaderblock'>
      <div className='loaderdiv'>
        <img width='150px' src={loader} />
      </div>
    </div>
  )
}
