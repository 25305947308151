import React, { useEffect, useState } from "react";
import { AuthGet, AuthPost, AuthPut } from "../../common_var/httpService";
import { useStateValue } from "../../Context/StateProvider";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-hot-toast";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

function Pricingdatabase() {
  const [incomeBin, setIncomeBin] = useState([]);
  const [gradeBin, setGradeBin] = useState([]);
  const [scoreBin, setScoreBin] = useState([]);
  const [scoreValue, setscoreValue] = useState([]);
  const [selectedObj, setSelectedObj] = useState([]);
  const [selectedValueObj, setSelectedValueObj] = useState([]);
  const [show, setShow] = useState(false);
  const [initialState] = useStateValue();
  const token = sessionStorage.getItem("token");
  const [deleteModal, setDeleteModal] = useState(false);

  const handleClose = () => {
    setShow(false);
  };
  const handleOpen = () => {
    setShow(true);
  };
  // start---fetch all data api-start
  const getPricingData = async () => {
    await AuthGet(
      `api/pricing-mapping/get-all/${initialState?.settingid}`,
      token
    )
      .then((res) => {
        if (res?.status === 200) {
          setIncomeBin(res?.incomeBin);
          setGradeBin(res?.gradeBin);
          setScoreBin(res?.scoreBin);
          setscoreValue(res?.scoreValue);
        }
        else{
          setIncomeBin([]);
          setGradeBin([]);
          setScoreBin([]);
          setscoreValue([]);
        }
      })
      .catch((err) => console.log(err));
  };
  // end---fetch all data api
  // start--- formik for modal
  const fromikModal = useFormik({
    initialValues: {
      apr: "",
      grades: "",
    },
    validationSchema: Yup.object({
      apr: Yup.number()
        .typeError("APR must be a number")
        .required("APR is required")
        .positive("APR must be a positive number"),
      grades: Yup.string().required("*Pass Through is required"),
    }),
    onSubmit: async (values) => {
      let formData = {
        score_id: +values.score_id,
        income_id: +values.income_id,
        settingId: initialState?.settingid,
        grade_id: +values.grades,
        apr: +values.apr,
        version_id: initialState?.version_id,
        pm_id: initialState?.pm_id,
      };
      await AuthPost(`api/pricing-mapping/add`, formData)
        .then((res) => {
          if (res.status == 200) {
            toast.success(res.message);
            fromikModal.resetForm();
            getPricingData();
          }
          if (res.status === 400) {
            toast.error("Bad Request");
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error(err.message);
        });
      handleClose();
      getPricingData();
    },
  });
  // end--- formik for modal

  // start--- formik for page
  const formik = useFormik({
    initialValues: {
      score: "",
      income: "",
      apr: "",
      grades: "",
    },
    validationSchema: Yup.object({
      score: Yup.string().required("*Score value is required"),
      income:
        incomeBin.length > 0
          ? Yup.string().required("*Income value is required")
          : "",
      apr: Yup.number()
        .min(0, "Apr cannot be negative")
        .test("decimal", "Apr must have two decimal places", (value) => {
          if (value !== undefined) {
            return /^-?\d+(?:\.\d{1,2})?$/.test(value.toString());
          }
          return true;
        })
        .typeError("*Apr must be a number")
        .required("*Apr  is required")
        .positive("*Apr must be a positive number"),
      grades: Yup.string().required("*Grade value is required"),
    }),
    onSubmit: async (values) => {
      let formData = {
        score_id: Number(selectedValueObj.score_id),
        income_id:
          initialState.pm_id === 1 ? null : Number(selectedValueObj.income_id),
        settingId: initialState?.settingid,
        grade_id: Number(values.grades),
        apr: Number(values.apr),
        version_id: initialState?.version_id,
        pm_id: initialState?.pm_id,
      };
      await AuthPost(`api/pricing-mapping/add`, formData)
        .then((res) => {
          if (res.status == 200) {
            toast.success(res.message);
            formik.resetForm();
            getPricingData();
          }
          if (res.status === 400) {
            toast.error("Bad Request");
          }
        })
        .catch((err) => {
          toast.error(err.message);
        });
      setSelectedValueObj([]);
    },
  });
  // end--- formik for page

  //start---modal open and prepopulate
  const handleModal = (val) => {
    handleOpen();
    fromikModal.setFieldValue("apr", val.apr);
    fromikModal.setFieldValue("grades", val.value_id);
    fromikModal.setFieldValue("score_id", val.score_id);
    fromikModal.setFieldValue("income_id", val.income_id);
    fromikModal.setFieldValue("id", val.id);
  };
  //end---modal open and prepopulate

  //start---handle change function for liner based pricing model
  const handleLinearChange = (name, selectedScore) => {
    if (name === "score") {
      const matchingEntry = scoreValue.find(
        (entry) => entry.score === selectedScore
      );
      setSelectedObj([matchingEntry]);
      setSelectedValueObj(matchingEntry.valueArr[0]);
      if (matchingEntry) {
        formik.setFieldValue("score", matchingEntry.score);
        formik.setFieldValue(
          "apr",
          matchingEntry.valueArr[0].apr !== null
            ? matchingEntry.valueArr[0].apr
            : ""
        );
        formik.setFieldValue(
          "grades",
          matchingEntry.valueArr[0].value !== null
            ? matchingEntry.valueArr[0].value_id
            : ""
        );
      }
    }
  };
  //end---handle change function for liner based pricing model

  //start---handle change function for risk based pricing model
  const handleRiskBasedChange = (name, selectedScore) => {
    if (name === "score") {
      const matchingEntry = scoreValue.find(
        (entry) => entry.score === selectedScore
      );
      setSelectedObj([matchingEntry]);
      if (matchingEntry) {
        formik.setFieldValue("score", matchingEntry.score);
        formik.setFieldValue("apr", matchingEntry.valueArr[0].apr);
        formik.setFieldValue("grades", matchingEntry.valueArr[0].value);
      }
    }
    if (name === "income" && selectedObj.length > 0) {
      formik.setFieldValue("income", selectedScore);
      const matchingEntry = scoreValue.find(
        (entry) => entry.score === formik.values.score
      );
      const incomeValueFound = matchingEntry.valueArr.some((value) => {
        if (value.income === selectedScore) {
          formik.setFieldValue("apr", value.apr === null ? "" : value.apr);
          formik.setFieldValue(
            "grades",
            value.value === null ? "" : value.value_id
          );
          setSelectedValueObj(value);
        }
      });
    }
  };
  //end---handle change function for risk based pricing model

  //start---update at page
  const handleUpdateChange = (event) => {
    setSelectedValueObj([]);
    formik.setFieldValue("apr", "");
    formik.setFieldValue("grades", "");
    const selectedScore = event.target.value;
    const name = event.target.name;
    selectedScore.replace(/[^0-9]/g, "");
    if (initialState.pm_id === 1) {
      handleLinearChange(name, selectedScore);
    } else {
      handleRiskBasedChange(name, selectedScore);
    }
  };
  //end---update at page

  //start---delete function in modal
  const handleDelete = () => {
    const id = Number(fromikModal.values.id);
    AuthPut(`api/pricing-mapping/gradeApr-inactive/${id}`, { active: false })
      .then((res) => {
        if (res?.status === 200 || res?.status === 201) {
          getPricingData();
          toast.success("Grade APR deleted successfully", { duration: 4000 });
          setDeleteModal(false);
        }
      })
      .catch((err) => {
        toast.error(err.message);
        setDeleteModal(false);
      });
    formik.resetForm();
  };
  //start---useEffect for initail call of settingID
  useEffect(() => {
    if (initialState?.settingid !== "") {
      getPricingData();
      formik.resetForm();
    }
  }, [initialState]);
  // end---useEffect for initail call of settingID

  return (
    <>
      <div className="whiteBg mb20">
        <form name="form1" onSubmit={formik.handleSubmit}>
          <div className="row   align-items-center">
            <div className="col-md-3">
              <div className="form-group">
                <label for="ContactName">Score</label>
                <select
                  className="form-select"
                  name="score"
                  id="score"
                  value={formik.values.score}
                  onChange={(e) => {
                    handleUpdateChange(e);
                  }}
                  onBlur={formik.handleBlur}
                >
                  <option value="">Select Score</option>
                  {scoreBin?.map((el) => (
                    <option key={el?.id} value={el?.score}>
                      {el?.score}
                    </option>
                  ))}
                </select>
                {formik.touched.score && formik.errors.score && (
                  <span className="error_text">{formik.errors.score}</span>
                )}
              </div>
            </div>

            {incomeBin.length > 0 ? (
              <div className="col-md-3">
                <div className="form-group">
                  <label for="ContactName">Income</label>
                  <select
                    className="form-select"
                    name="income"
                    id="income"
                    value={formik.values.income}
                    onChange={(e) => {
                      handleUpdateChange(e);
                    }}
                    onBlur={formik.handleBlur}
                  >
                    <option value="">Select Income</option>
                    {incomeBin.map((el) => (
                      <option key={el.id} value={el.income}>
                        {el.income}
                      </option>
                    ))}
                  </select>
                  {formik.touched.income && formik.errors.income && (
                    <span className="error_text">{formik.errors.income}</span>
                  )}
                </div>
              </div>
            ) : (
              ""
            )}

            <div className="col-md-2">
              <div className="form-group">
                <label for="ContactName">APR(%)</label>
                <input
                  type="text"
                  name="apr"
                  className="form-control"
                  placeholder="Enter APR"
                  id="apr"
                  value={formik.values.apr}
                  onChange={(e) => {
                    formik.setFieldValue(
                      "apr",
                      e.target.value
                        .replace(/[^\d\.]/g, "")
                        .replace(/^0+/g, "")
                        .replace(/\.(\d{0,2}).*/g, ".$1")
                    );
                  }}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.apr && formik.errors.apr && (
                  <span className="error_text">{formik.errors.apr}</span>
                )}
              </div>
            </div>

            <div className="col-md-2">
              <div className="form-group">
                <label for="ContactName">Grade</label>
                <select
                  className="form-select"
                  name="grades"
                  id="grades"
                  value={formik.values.grades}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                >
                  <option value="">Select Grade</option>
                  {gradeBin.map((el) => (
                    <option key={el.id} value={el.id}>
                      {el.value}
                    </option>
                  ))}
                </select>
                {formik.touched.grades && formik.errors.grades && (
                  <span className="error_text">{formik.errors.grades}</span>
                )}
              </div>
            </div>

            <div className="col-md-2">
              <div className="form-group">
                {selectedValueObj.length === 0 ? (
                  <button type="submit" className="btn btn-success mt18">
                    ADD
                  </button>
                ) : selectedValueObj.apr !== null &&
                  selectedValueObj.value !== null ? (
                  <button type="submit" className="btn btn-success mt18">
                    UPDATE
                  </button>
                ) : (
                  <button type="submit" className="btn btn-success mt18">
                    ADD
                  </button>
                )}
              </div>
            </div>
          </div>
        </form>
      </div>
      <div>
        {scoreValue.length === 0 ? (
          <div className="whiteBg">No Income and Score Data Available</div>
        ) : (
          <div className="row flexwrap">
            {/* value table starts */}
            <div className="col-md-6">
              <div className=" ">
                <div className="productHeader font_Weight_bold textAlignCenter pricing_model_header">
                  <div>Score Bin</div>
                  <div>{incomeBin.length > 0 ? "Income" : "Grade"}</div>
                </div>
                <div className="tableResponsive">
                  <table className="table mainTable fs12px">
                    <thead className="fs12px">
                      <tr className="textAlignCenter">
                        {/* {scoreValue.length > 0 && (
                          <th className="whitespacenowrap textAlignLeft ">
                            Score Bin
                          </th>
                        )} */}
                        {incomeBin.length > 0 ? <th></th> : ""}
                        {incomeBin.length > 0
                          ? incomeBin?.map((el) => (
                              <>
                                <th>{el?.income}</th>
                              </>
                            ))
                          : ""}
                      </tr>
                    </thead>
                    <tbody>
                      {scoreValue.length > 0 &&
                        scoreValue?.map((i) => {
                          return (
                            <tr className="test">
                              <td className="font_Weight_bold pm_cell_width">
                                {i?.score}
                              </td>
                              {i?.valueArr?.map((j) => {
                                return (
                                  <>
                                    <td
                                      className="textAlignCenter"
                                      onClick={() => {
                                        j.value !== null && handleModal(j);
                                      }}
                                    >
                                      {j.value === null ? "-" : j.value}
                                    </td>
                                  </>
                                );
                              })}
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            {/* value table end */}

            {/* apr table starts */}
            <div className="col-md-6">
              {" "}
              <div className=" ">
                <div className="productHeader font_Weight_bold textAlignCenter pricing_model_header">
                  <div>Score Bin</div>
                  <div>APR(%)</div>
                </div>
                <div className="tableResponsive">
                  <table className="table mainTable fs12px">
                    <thead>
                      <tr className="textAlignCenter">
                        {/* {scoreValue.length > 0 && (
                          <th className="whitespacenowrap textAlignLeft">
                            Score Bin
                          </th>
                        )} */}
                        {/* <th></th> */}
                        {incomeBin.length > 0 ? <th></th> : ""}
                        {incomeBin.length > 0
                          ? incomeBin?.map((el) => (
                              <>
                                <th>{el?.income}</th>
                              </>
                            ))
                          : ""}
                      </tr>
                    </thead>
                    <tbody>
                      {scoreValue?.map((i) => {
                        return (
                          <tr>
                            <td className="font_Weight_bold pm_cell_width">
                              {i?.score}
                            </td>
                            {i?.valueArr?.map((j) => {
                              return (
                                <>
                                  <td
                                    className="textAlignCenter"
                                    onClick={() => {
                                      j.value !== null && handleModal(j);
                                    }}
                                  >
                                    {j.apr === null ? "-" : `${j.apr} %`}
                                  </td>
                                </>
                              );
                            })}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                <Modal
                  show={show}
                  onHide={() => {
                    handleClose();
                    fromikModal.resetForm();
                  }}
                >
                  <Modal.Body>
                    <form onSubmit={fromikModal.handleSubmit}>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label for="ContactName">Grade</label>
                            <select
                              className="form-select"
                              name="grades"
                              id="grades"
                              value={fromikModal.values.grades}
                              onChange={fromikModal.handleChange}
                              onBlur={fromikModal.handleBlur}
                            >
                              <option value="">Select Grade</option>
                              {gradeBin.map((el) => (
                                <option key={el.id} value={el.id}>
                                  {el.value}
                                </option>
                              ))}
                            </select>
                          </div>

                          {fromikModal.touched.grades &&
                            fromikModal.errors.grades && (
                              <span className="error_text">
                                {fromikModal.errors.grades}
                              </span>
                            )}
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label for="ContactName">APR(%)</label>
                            <input
                              type="text"
                              name="apr"
                              className="form-control"
                              placeholder="Enter APR"
                              id="apr"
                              value={fromikModal.values.apr}
                              onChange={(e) => {
                                fromikModal.setFieldValue(
                                  "apr",
                                  e.target.value
                                    .replace(/[^\d\.]/g, "")
                                    .replace(/^0+/g, "")
                                    .replace(/\.(\d{0,2}).*/g, ".$1")
                                );
                              }}
                              onBlur={fromikModal.handleBlur}
                            />
                            {fromikModal.touched.apr &&
                              fromikModal.errors.apr && (
                                <span className="error_text">
                                  {fromikModal.errors.apr}
                                </span>
                              )}
                          </div>
                        </div>
                      </div>

                      <Modal.Footer>
                        <Button
                          variant="secondary"
                          onClick={() => {
                            handleClose();
                            fromikModal.resetForm();
                          }}
                        >
                          Cancel
                        </Button>
                        <Button
                          variant="secondary"
                          onClick={() => {
                            setDeleteModal(true);
                            setShow(false);
                          }}
                        >
                          Delete
                        </Button>
                        <Button type="submit">Update</Button>
                      </Modal.Footer>
                    </form>
                  </Modal.Body>
                </Modal>
              </div>
            </div>
            {/* apr table ends */}

            <Modal
              show={deleteModal}
              onHide={() => {
                setDeleteModal(false);
              }}
            >
              <Modal.Header closeButton>
                <Modal.Title>Delete Income and APR</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p>Are you sure you want to delete income and APR?</p>
                <Modal.Footer>
                  <Button
                    variant="secondary"
                    onClick={() => {
                      setDeleteModal(false);
                      formik.resetForm();
                    }}
                  >
                    No
                  </Button>
                  <Button
                    type="button"
                    onClick={() => {
                      handleDelete();
                    }}
                  >
                    Yes
                  </Button>
                </Modal.Footer>
                {/* </form> */}
              </Modal.Body>
            </Modal>
          </div>
        )}
      </div>

      {/* </div> */}
    </>
  );
}

export default Pricingdatabase;
