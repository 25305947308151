import React from "react";
import { toast } from "react-hot-toast";
import { Outlet, Navigate, useNavigate } from "react-router-dom";
import { useStateValue } from "../../Context/StateProvider";
import Toast from "../Toaster/Toast";

const Guard = () => {
  let navigate = useNavigate();
  let token = sessionStorage.getItem("token");
  let admin_id = sessionStorage.getItem("admin_id");
  let expiry = token !== null && JSON?.parse(atob(token?.split(".")[1]))?.exp;
  let tokenFlag = false;

  if (token !== null) {
    let payload = JSON.parse(atob(token.split(".")[1])).payload;
    if (payload.admin_id === admin_id && payload.role === "admin") {
      tokenFlag = true;
    }
  }

  return (
    <>
      <Toast />
      {expiry === false ? (
        <div>
          {" "}
          <Navigate to="/unauthorized" />
        </div>
      ) : (
        <div>
          {expiry > Math.floor(new Date().getTime() / 1000) ? (
            <div>
              {tokenFlag ? <Outlet /> : <Navigate to="/unauthorized" />}
            </div>
          ) : (
            <Navigate to="/sessionexpired" />
          )}
        </div>
      )}
    </>
  );
};

export default Guard;
