import { useState, useEffect } from "react";
import * as Yup from "yup";
import { useStateValue } from "../../Context/StateProvider";
import toast from "react-hot-toast";
import { AuthGet, AuthPost, AuthPut } from "../../common_var/httpService";
import { useFormik } from "formik";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

export const OfferMapping = () => {
  const [initialState] = useStateValue();
  const token = sessionStorage.getItem("token");
  const [data, setdata] = useState([]);
  const [grade, setGrade] = useState([]);
  const [apr, setApr] = useState([]);
  const [offer, setOffer] = useState([]);
  const [offerValue, setOfferValue] = useState([]);
  const [selectedOffers, setSelectedOffers] = useState([]);
  const [show, setShow] = useState(false);
  const [deleteRowId, setDeleteRowId] = useState(null);



  useEffect(() => {
    if (initialState?.settingid !== "") {
      getAllData();
      addOffersMapping.resetForm();
    }
  }, [initialState]);

  const getAllData = async () => {
    await AuthGet(
      `api/offers-mapping/get-all/${initialState?.settingid}`,
      token
    )
      .then((res) => {
        if (res?.status === 200) {
          setApr(res.apr)
          // console.log("All data: ", res);
          setdata(res);
          setGrade(res.grades);
          setOffer(res.offers);
          setOfferValue(res.offerValue);
        }
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  const offersMappingValidations = Yup.object({
    grade: Yup.string().required("Grade value is required"),
    offerName: Yup.string().required("Offer name is required"),
    offerValue : Yup.string().required("Offer value is required"),
    minAPR: Yup.number()
      .typeError("Apr value must be a number")
      .required("Min Apr is required")
      .positive("Apr value must be a positive number"),
    // .lessThan(Yup.ref("maxAPR")),
    maxAPR: Yup.number()
      .typeError("Apr value must be a number")
      .required("Max Apr is required")
      .positive("Apr value must be a positive number")
      .moreThan(Yup.ref("minAPR")),
  });

  const addOffersMapping = useFormik({
    initialValues: {
      grade: "",
      offerName: "",
      offerValue: "",
      minAPR: "",
      maxAPR: "",
    },
    validationSchema: offersMappingValidations,
    onSubmit: async (values) => {
      let sendData = {
        gradeId: +values.grade,
        offerValId: +values.offerValue,
        minApr: +values.minAPR,
        maxApr: +values.maxAPR,
        settingId: initialState?.settingid,
        version_id:initialState?.version_id,
        pm_id: initialState?.pm_id
      };
      await AuthPost(`api/offers-mapping/add`, sendData)
        .then((res) => {
          if (res?.status === 200 || res?.status === 201) {
            addOffersMapping.resetForm();
            toast.success("Offer Mapping added successfully", {
              duration: 4000,
            });
            getAllData();
            addOffersMapping.resetForm();
          } else {
            toast.error(res.message, { duration: 4000 });
          }
        })
        .catch((err) => {
          console.log("Error: ", err);
          toast.error(err.message, { duration: 4000 });
        });
    },
  });

  const deleteRecord = async () => {
    await AuthPut(`api/offers-mapping/inactive/${deleteRowId}`, { active: false }, token)
      .then((res) => {
        if (res?.status === 200) {
          // setdata(res?.data);
          getAllData();
          handleClose()
          toast.success(res.message)
        }
      })
      .catch((err) => {
        toast.error(err.message);
        handleClose()
      });
  };

  // onChange function for selecting the offerValue for selected offerLabel
  const handleOfferValue = (e) => {
    addOffersMapping.setFieldValue("offerName", e.target.value);
    let sortedOffers = offerValue.filter((el) => {
      if (el.offer_label === e.target.value) {
        return el;
      }
    });
    setSelectedOffers(sortedOffers);
  };

  const handleClose = () => {
    setShow(false);
  };
  const handleOpen = () => {
    setShow(true);
  };

  const handleSelectEdit = (val) => {
    handleOpen();
  };

   //Apr prepopulation
   const handeChangeApr = (e) =>{
     setApr ([]);
     let filtered=[]
    addOffersMapping.setFieldValue("minAPR","");
    addOffersMapping.setFieldValue("maxAPR","");
    const value = e.target.value;
    const name =  e.target.name;
    if(name === 'grade'){
        data?.grades?.filter((e)=>{
          if(e.id == value){
            data?.apr?.filter((f)=>{
              if(f.grade_description===e.grade_description){
                filtered.push(f)
                return;
              }   
            })
          }
        }) 
    }
    addOffersMapping.setFieldValue("minAPR",filtered[0]?.min?filtered[0].min:"")
    addOffersMapping.setFieldValue("maxAPR",filtered[0]?.max?filtered[0].max:"")
   }
 
  console.log('addOffersMapping.errors::: ', addOffersMapping.errors);

  return (
    <div className=" ">
      <div>
        <div className="mb20 whiteBg">
          <form>
            <div className=" ">
              <div className="row align-items-center">
                <div className="col-md-2">
                  <div className="form-group">
                    <label>Grade</label>
                    <select
                      className="form-select"
                      name="grade"
                      onClick={(e)=>{
                        handeChangeApr(e)
                      }}
                      onChange={(addOffersMapping.handleChange)}
                      onBlur={addOffersMapping.handleBlur}
                      value={addOffersMapping.values.grade}
                    >
                      <option value="">Select Grade</option>
                      {grade?.map((grade) => (
                        <option key={grade.id} value={grade.id}>
                          {grade.grade_description}
                        </option>
                      ))}
                    </select>
                    {addOffersMapping.touched.grade &&
                      addOffersMapping.errors.grade && (
                        <span className="error_text">
                          {addOffersMapping.errors.grade}
                        </span>
                      )}
                  </div>
                </div>

                <div className="col-md-2">
                  <div className="form-group">
                    <label>Offer Name</label>
                    <select
                      className="form-select"
                      name="offerName"
                      onChange={handleOfferValue}
                      onBlur={addOffersMapping.handleBlur}
                      value={addOffersMapping.values.offerName}
                    >
                      <option value="">Select Offer</option>
                      {offer?.map((offer) => (
                        <option key={offer?.id} value={offer?.offer_label}>
                          {offer?.offer_label}
                        </option>
                      ))}
                    </select>
                    {addOffersMapping.touched.offerName &&
                      addOffersMapping.errors.offerName && (
                        <span className="error_text">
                          {addOffersMapping.errors.offerName}
                        </span>
                      )}
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="form-group">
                    <label>Offer Value</label>
                    <select
                      className="form-select"
                      name="offerValue"
                      onChange={addOffersMapping.handleChange}
                      onBlur={addOffersMapping.handleBlur}
                      value={addOffersMapping.values.offerValue}
                    >
                      <option value="">Select Value</option>
                      {selectedOffers?.map((offer) => (
                        <option
                          key={offer?.offer_value_id}
                          value={offer?.offer_value_id}
                        >
                          {offer?.offer_value}
                        </option>
                      ))}
                    </select>
                    {addOffersMapping.touched.offerValue &&
                      addOffersMapping.errors.offerValue && (
                        <span className="error_text">
                          {addOffersMapping.errors.offerValue}
                        </span>
                      )}
                  </div>
                </div>
                <div className="col-md-2 ">
                  <div className="form-group">
                    <label>Minimum APR</label>
                    <input
                      name="minAPR"
                      placeholder="Enter Min APR"
                      formControlName="minAPR"
                      id="minAPR"
                      onChange={(e) => {
                        addOffersMapping.setFieldValue(
                          "minAPR",
                          e.target.value
                            .replace(/[^\d\.]/g, "")
                            .replace(/^0+/g, "")
                            .replace(/\.(\d{0,2}).*/g, ".$1")
                        );
                      }}
                      onBlur={addOffersMapping.handleBlur}
                      value={addOffersMapping.values.minAPR}
                      className="form-control"
                    />
                    {addOffersMapping.touched.minAPR &&
                      addOffersMapping.errors.minAPR && (
                        <span className="error_text">
                          {addOffersMapping.errors.minAPR}
                        </span>
                      )}
                  </div>
                </div>
                <div className="col-md-2 ">
                  <div className="form-group">
                    <label>Maximum APR</label>
                    <input
                      name="maxAPR"
                      placeholder="Enter Max APR"
                      formControlName="maxAPR"
                      id="maxAPR"
                      onChange={(e) => {
                        addOffersMapping.setFieldValue(
                          "maxAPR",
                          e.target.value
                            .replace(/[^\d\.]/g, "")
                            .replace(/^0+/g, "")
                            .replace(/\.(\d{0,2}).*/g, ".$1")
                        );
                      }}
                      onBlur={addOffersMapping.handleBlur}
                      value={addOffersMapping.values.maxAPR}
                      className="form-control"
                    />{" "}
                    {addOffersMapping.touched.maxAPR &&
                      addOffersMapping.errors.maxAPR && (
                        <span className="error_text">
                          {addOffersMapping.errors.maxAPR}
                        </span>
                      )}
                  </div>
                </div>
                <div className="col-md-2 ">
                  <button
                    type="submit"
                    className="btn btn-success  "
                    onClick={addOffersMapping.handleSubmit}
                  >
                    ADD
                  </button>

                  {/* <button
                    className="btn btn-outline-success displayFlex AlignItemCenter"
                    type="submit"
                    onClick={addOffersMapping.handleSubmit}
                  >
                    <i className="fas fa-plus"></i>

                    <div className="pl10"> ADD </div>
                  </button> */}
                </div>
              </div>
            </div>
          </form>
        </div>
        <div className="row">
          <div className="col-12">
            <form name="form">
              <div className="tableResponsive">
                <table className="table mainTable">
                  <thead>
                    <tr>
                      <th style={{ width: "5%" }}>S.No</th>
                      <th style={{ width: "10%" }}>Grade</th>
                      <th style={{ width: "10%" }}>Offer Name</th>
                      <th style={{ width: "10%" }}>Offer Value</th>
                      <th style={{ width: "10%" }}>Minimum Apr</th>
                      <th style={{ width: "10%" }}>Maximum Apr</th>
                      <th style={{ width: "10%" }}>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.current_data?.length > 0 ? (
                      data?.current_data?.map((data, index) => (
                        <tr key={data?.offer_grade_id}>
                          <td scope="row">{index + 1}</td>

                          <td>{data?.grade}</td>

                          <td>{data?.offer_name}</td>

                          <td>{data?.offer_value}</td>

                          <td>{data?.min_apr}</td>

                          <td>{data?.max_apr}</td>

                          <td>
                            <button
                              className="btn btn-outline-danger"
                              type="button"
                              onClick={() => {
                                setShow(true);
                                setDeleteRowId(data.id);
                              }}
                            >
                              <i class="fa fa-trash"></i>
                            </button>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <td colSpan="7" className="nodatarow">
                        No Offers Available for the Selected Provider
                      </td>
                    )}
                  </tbody>
                </table>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Modal
        show={show}
        onHide={() => {
          handleClose();
          // formik.resetForm();
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Offer Mapping</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to Mapped Offer?</p>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                handleClose();
                // formik.resetForm();
              }}
            >
              No
            </Button>
            <Button type="button" onClick={() => deleteRecord()}>
              Yes
            </Button>
          </Modal.Footer>
          {/* </form> */}
        </Modal.Body>
        {/* inactivateTerm(data?.term_grade_id) */}
      </Modal>
    </div>
  );
};
