import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { AuthGet, AuthPost, AuthPut } from "../../common_var/httpService";
import { useStateValue } from "../../Context/StateProvider";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

function Pricingmodel() {
  const [{ settingid,version_id,pm_id,stateCode }] = useStateValue();
  const [score_n_offerData, setScore_n_offerData] = useState();
  const [scoreEdit, setScoreEdit] = useState(null);
  const [scoreEditValue, setScoreEditValue] = useState();
  const [incomeData, setIncomeData] = useState();
  const [incomeEdit, setIncomeEdit] = useState(null);
  const [show, setShow] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const [incomeEditValue, setIncomeEditValue] = useState();

  const getScore_n_OffersData = async () => {
    await AuthGet(`api/pricing-model/get-all-scores/${settingid}`).then(
      (res) => {
        if (res.status === 200) {
          setScore_n_offerData(res);
        } else {
          toast.error(res.message, { duration: 4000 });
        }
      }
    );
  };
  const handleClose = () => {
    setShow(false);
  };
  const handleOpen = () => {
    setShow(true);
  };

  // const handleSelectEdit = (val) => {
  //   handleOpen();
  // };
  const getIncomData = async () => {
    await AuthGet(`api/pricing-model/get-all-income/${settingid}`).then(
      (res) => {
        if (res.status === 200) {
          setIncomeData(res);
        } else {
          toast.error(res.message, { duration: 4000 });
        }
      }
    );
  };

  const scoreValidationSchema = Yup.object({
    fromScore: Yup.number()
      .typeError("From score must be a number")
      .required("*From score is required"),
    // .positive(" From score must be a positive number"),

    toScore: Yup.number()
      .required("*To score is required")
      .positive(" *To score must be a positive number")
      .typeError("*To score must be a number")
      .moreThan(Yup.ref("fromScore")),
  });

  const addScore = useFormik({
    initialValues: {
      fromScore: "",
      toScore: "",
    },
    validationSchema: scoreValidationSchema,
    onSubmit: async (values) => {
      values.fromScore = +values.fromScore;
      values.toScore = +values.toScore;
      values.version_id= version_id;
      values.pm_id = pm_id;
      console.log("values::: ", values);
      // values.offerId = parseInt(values.offerId);
      values.settingId = settingid;
      await AuthPost(`api/pricing-model/add-score`, values).then((res) => {
        if (res?.status === 200) {
          addScore.resetForm();
          getScore_n_OffersData();
          toast.success("Score added successfully", { duration: 4000 });
        } else {
          toast.error(res.message, { duration: 4000 });
        }
      });
    },
  });

  const editScore = useFormik({
    initialValues: {
      fromScore: "",
      toScore: "",
    },
    enableReinitialize: true,
    validationSchema: scoreValidationSchema,
    onSubmit: async (values) => {
      values.fromScore = parseInt(values.fromScore);
      values.toScore = parseInt(values.toScore);
      await AuthPut(
        `api/pricing-model/update-score/${scoreEditValue?.id}`,
        values
      ).then((res) => {
        if (res?.status === 200) {
          setScoreEdit(null);
          toast.success("Score updated successfully", { duration: 4000 });
          getScore_n_OffersData();
        } else {
          toast.error(res.message, { duration: 4000 });
        }
      });
    },
  });

  const deleteScore = async () => {
    await AuthPut(`api/pricing-model/score-inactive/${deleteId.id}`, {
      active: false,
      settingId: settingid,
    }).then((res) => {
      if (res?.status === 200 || res?.status === 201) {
        getScore_n_OffersData();
        toast.success("Score deleted successfully", { duration: 4000 });
        handleClose();
        setDeleteId(null);
      } else {
        toast.error(res.message);
        handleClose();
        setDeleteId(null);
      }
    });
  };

  const incomeValidationSchema = Yup.object({
    maxIncome: Yup.number()
      .required("*Maximum income is required")
      .typeError("Maximum income  must be a number")
      .positive(" Maximum income  must be a positive number")
      .moreThan(Yup.ref("minIncome")),
    minIncome: Yup.number()
      .required("*Minimum income is required")
      .typeError("Minimum income  must be a number"),
    // .positive(" Minimum income  must be a positive number"),
    // .typeError("Maximum Income  must be a number")
    // minIncome: Yup.number()
    //   .required("*Minimum income is required")
    //   .typeError("Minimum income  must be a number")
    //   .positive(" Minimum income  must be a positive number"),
  });

  const addIncome = useFormik({
    initialValues: {
      maxIncome: "",
      minIncome: "",
    },
    validationSchema: incomeValidationSchema,
    onSubmit: async (values) => {
      values.maxIncome = parseInt(values.maxIncome);
      values.minIncome = parseInt(values.minIncome);
      values.settingId = settingid;
      values.version_id = version_id;
      values.pm_id = pm_id;

      console.log("values::: ", values);
      await AuthPost(`api/pricing-model/add-income`, values).then((res) => {
        if (res?.status === 200) {
          addIncome.resetForm();
          getIncomData();
          toast.success("Income added successfully", { duration: 4000 });
        } else {
          toast.error(res.message, { duration: 4000 });
        }
      });
    },
  });
  const editIncome = useFormik({
    initialValues: {
      maxIncome: incomeEditValue?.maxIncome ? incomeEditValue?.maxIncome : "",
      minIncome: incomeEditValue?.minIncome ? incomeEditValue?.minIncome : "",
    },
    enableReinitialize: true,
    validationSchema: incomeValidationSchema,
    onSubmit: async (values) => {
      values.maxIncome = parseInt(values.maxIncome);
      values.minIncome = parseInt(values.minIncome);
      values.settingId = settingid;

      await AuthPut(
        `api/pricing-model/update-income/${incomeEditValue?.id}`,
        values
      ).then((res) => {
        if (res?.status === 200) {
          setIncomeEdit(null);
          toast.success("Income updated successfully", { duration: 4000 });
          getIncomData();
        } else {
          toast.error(res.message, { duration: 4000 });
        }
      });
    },
  });
  console.log("editScore.values::: ", editScore.values);

  const deleteIncome = async () => {
    await AuthPut(`api/pricing-model/income-inactive/${deleteId.id}`, {
      active: false,
      settingId: settingid,
    }).then((res) => {
      if (res?.status === 200 || res?.status === 201) {
        getIncomData();
        toast.success("Income deleted successfully", { duration: 4000 });
        handleClose();
        setDeleteId(null);
      } else {
        toast.error(res.message);
        handleClose();
        setDeleteId(null);
      }
    });
  };

  useEffect(() => {
    if (settingid) {
      getScore_n_OffersData();
      getIncomData();
    }
  }, [settingid,stateCode]);

  return (
    <div className=" ">
      <div className="mb20 ">
        <div className="row">
          <div className="col-md-6 ">
            <form onSubmit={addScore.handleSubmit}>
              <div className="whiteBg">
                <div className="row">
                  <div className="col-md-5 ">
                    <div className="form-group">
                      <label for="fromScore">From Score</label>
                      <input
                        name="fromScore"
                        className="form-control"
                        placeholder="Enter From Score"
                        id="fromScore"
                        onChange={(e) => {
                          addScore.setFieldValue(
                            "fromScore",
                            e.target.value
                              .replace(/[^0-9]/g, "")
                              // .replace(/^0+/g, "")
                              .replace(/\.(\d{0,2}).*/g, ".$1")
                          );
                        }}
                        onBlur={addScore.handleBlur}
                        value={addScore.values.fromScore}
                      />
                      {addScore.touched.fromScore &&
                      addScore.errors.fromScore ? (
                        <span className="error_text text-danger">
                          {addScore.errors.fromScore}
                        </span>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-5 ">
                    <div className="form-group">
                      <label for="toScore">To Score</label>
                      <input
                        name="toScore"
                        className="form-control"
                        placeholder="Enter To Score"
                        id="toScore"
                        onChange={(e) => {
                          addScore.setFieldValue(
                            "toScore",
                            e.target.value.replace(/[^0-9]/g, "")
                          );
                        }}
                        onBlur={addScore.handleBlur}
                        value={addScore.values.toScore}
                      />
                      {addScore.touched.toScore && addScore.errors.toScore ? (
                        <span className="error_text text-danger">
                          {addScore.errors.toScore}
                        </span>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-2 ">
                    <div className="form-group">
                      <button type="submit" className="btn btn-success mt18">
                        ADD
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="col-md-6">
            <div className="whiteBg">
              <form onSubmit={addIncome.handleSubmit}>
                <div className="row">
                  <div className="col-md-5 ">
                    <div className="form-group">
                      <label for="minIncome">Min Income</label>
                      <input
                        name="minIncome"
                        className="form-control"
                        placeholder="Enter Min Income"
                        id="minIncome"
                        onChange={(e) => {
                          addIncome.setFieldValue(
                            "minIncome",
                            e.target.value.replace(/[^0-9]/g, "")
                          );
                        }}
                        onBlur={addIncome.handleBlur}
                        value={addIncome.values.minIncome}
                      />
                      {addIncome.touched.minIncome &&
                      addIncome.errors.minIncome ? (
                        <span className="error_text text-danger">
                          {addIncome.errors.minIncome}
                        </span>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-5 ">
                    {" "}
                    <div className="form-group">
                      <label for="maxIncome">Max Income</label>
                      <input
                        name="maxIncome"
                        className="form-control"
                        placeholder="Enter Max Income"
                        id="maxIncome"
                        onChange={(e) => {
                          addIncome.setFieldValue(
                            "maxIncome",
                            e.target.value.replace(/[^0-9]/g, "")
                          );
                        }}
                        onBlur={addIncome.handleBlur}
                        value={addIncome.values.maxIncome}
                      />
                      {addIncome.touched.maxIncome &&
                      addIncome.errors.maxIncome ? (
                        <span className="error_text text-danger">
                          {addIncome.errors.maxIncome}
                        </span>
                      ) : null}
                    </div>{" "}
                  </div>
                  <div className="col-md-2 ">
                    <div className="form-group">
                      <button type="submit" className="btn btn-success mt18">
                        ADD
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div className="row  align-items-center">
          <div className="col-md-3"></div>

          <div className="col-md-3"></div>

          {/* <div className="col-md-3">
              <div className="form-group">
                <label for="ContactName">Offer Value</label>
                <select
                  className="form-select"
                  name="offerId"
                  onChange={addScore.handleChange}
                  onBlur={addScore.handleBlur}
                  value={addScore.values.offerId}
                >
                  <option defaultValue value="default">
                    Offer Value
                  </option>
                  {score_n_offerData?.allOffers?.map((offers) => (
                    <option value={offers.id}>{offers.offer_label}</option>
                  ))}
                </select>
                {addScore.touched.offerId && addScore.errors.offerId ? (
                  <span className="error_text text-danger">
                    {addScore.errors.offerId}
                  </span>
                ) : null}
              </div>
            </div> */}

          <div className="col-md-3"></div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-6">
          <form name="form">
            <div className="tableResponsive">
              <table className="table mainTable">
                <thead>
                  <tr>
                    <th style={{ width: "10%" }}>S.No</th>
                    <th style={{ width: "20%" }}>From Score</th>
                    <th style={{ width: "30%" }}>To Score</th>
                    {/* <th style={{ width: '20%' }}>Offer Value</th> */}
                    <th style={{ width: "40%" }}>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {score_n_offerData?.allScores.length > 0 ? (
                    score_n_offerData?.allScores.map((data, index) => (
                      <tr key={data.id}>
                        <td scope="row">{index + 1}</td>

                        <td>
                          {scoreEdit === data.id ? (
                            <>
                              <input
                                name="fromScore"
                                className="form-control"
                                placeholder="Enter From Score"
                                id="editScoreFromScore"
                                // onChange={(e) => {
                                //   setScoreEditValue({
                                //     ...scoreEditValue,
                                //     fromScore: e.target.value.replace(
                                //       /[^0-9]/g,
                                //       ""
                                //     ),
                                //   });
                                // }}
                                onChange={(e) => {
                                  editScore.setFieldValue(
                                    "fromScore",
                                    e.target.value.replace(/[^0-9]/g, "")
                                  );
                                }}
                                onBlur={editScore.handleBlur}
                                value={editScore.values.fromScore}
                              />

                              {editScore.touched.fromScore &&
                              editScore.errors.fromScore ? (
                                <p className="error_text text-danger">
                                  {editScore.errors.fromScore}
                                </p>
                              ) : null}
                            </>
                          ) : (
                            <span>{data.fromscore}</span>
                          )}
                        </td>

                        <td>
                          {scoreEdit === data.id ? (
                            <>
                              <input
                                name="toScore"
                                className="form-control"
                                placeholder="Enter To Score"
                                id="editScoretToScore"
                                onChange={(e) => {
                                  editScore.setFieldValue(
                                    "toScore",
                                    e.target.value.replace(/[^0-9]/g, "")
                                  );
                                }}
                                onBlur={editScore.handleBlur}
                                value={editScore.values.toScore}
                              />

                              {editScore.touched.toScore &&
                              editScore.errors.toScore ? (
                                <p className="error_text text-danger">
                                  {editScore.errors.toScore}
                                </p>
                              ) : null}
                            </>
                          ) : (
                            <span>{data.toscore}</span>
                          )}
                        </td>

                        {/* <td>
                      {scoreEdit === data.id ? (
                        <>
                          <select
                            className="form-select"
                            name="offerId"
                            onChange={(e) =>
                              setScoreEditValue({
                                ...scoreEditValue,
                                offerId: e.target.value,
                              })
                            }
                            onBlur={editScore.handleBlur}
                            value={editScore.values.offerId}
                          >
                            <option defaultValue value="default">
                              Offer Value
                            </option>
                            {score_n_offerData?.allOffers?.map((offers) => (
                              <option value={offers.id}>
                                {offers.offer_label}
                              </option>
                            ))}
                          </select>

                          {editScore.touched.offerId &&
                            editScore.errors.offerId ? (
                            <p className="error_text text-danger">
                              {editScore.errors.offerId}
                            </p>
                          ) : null}
                        </>
                      ) : (
                        <span>{data.offer_label}</span>
                      )}
                    </td> */}
                        <td>
                          {scoreEdit === data.id ? (
                            <div>
                              <button
                                className="btn btn-primary"
                                type="button"
                                onClick={() => {
                                  editScore.handleSubmit();
                                }}
                              >
                                Save
                              </button>{" "}
                              &nbsp;
                              <button
                                className="btn btn-primary"
                                type="button"
                                onClick={() => {
                                  setScoreEdit(null);
                                }}
                              >
                                Cancel
                              </button>
                            </div>
                          ) : (
                            <div>
                              <button
                                disabled
                                className="btn btn-outline-primary"
                                type="button"
                                onClick={() => {
                                  setScoreEdit(data.id);
                                  setScoreEditValue({
                                    fromScore: data.fromscore,
                                    toScore: data.toscore,
                                    id: data.id,
                                  });
                                  editScore.setFieldValue(
                                    "fromScore",
                                    Number(data.fromscore)
                                  );
                                  editScore.setFieldValue(
                                    "toScore",
                                    Number(data.toscore)
                                  );
                                }}
                              >
                                <i className="fa fa-pencil-square-o"></i>
                              </button>{" "}
                              &nbsp;
                              <button
                                disabled
                                className="btn btn-outline-danger"
                                onClick={() => {
                                  handleOpen();
                                  setDeleteId({ id: data.id, type: "score" });
                                }}
                                type="button"
                              >
                                <i className="fa fa-trash"></i>
                              </button>
                            </div>
                          )}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <td colSpan="7" className="nodatarow">
                      No Scores Available for the Selected Provider
                    </td>
                  )}
                </tbody>
              </table>
            </div>
          </form>
        </div>
        <div className="col-md-6">
          <form name="form">
            <div className="tableResponsive">
              <table className="table mainTable">
                <thead>
                  <tr>
                    <th style={{ width: "10%" }}>S.No</th>
                    <th style={{ width: "30%" }}>Min Income</th>
                    <th style={{ width: "20%" }}>Max Income</th>
                    <th style={{ width: "40%" }}>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {incomeData?.data?.length > 0 ? (
                    incomeData?.data.map((data, index) => (
                      <tr key={data.id}>
                        <td scope="row">{index + 1}</td>

                        <td>
                          {incomeEdit === data.id ? (
                            <>
                              <input
                                name="minIncome"
                                className="form-control"
                                placeholder="Enter Min Income"
                                id="editMinIncome"
                                onChange={(e) => {
                                  setIncomeEditValue({
                                    ...incomeEditValue,
                                    minIncome: e.target.value.replace(
                                      /[^0-9]/g,
                                      ""
                                    ),
                                  });
                                }}
                                onBlur={editIncome.handleBlur}
                                value={editIncome.values.minIncome}
                              />

                              {editIncome.touched.minIncome &&
                              editIncome.errors.minIncome ? (
                                <p className="error_text text-danger">
                                  {editIncome.errors.minIncome}
                                </p>
                              ) : null}
                            </>
                          ) : (
                            <span>{data.min_income}</span>
                          )}
                        </td>

                        <td>
                          {incomeEdit === data.id ? (
                            <>
                              <input
                                name="maxIncome"
                                className="form-control"
                                placeholder="Enter Max Income"
                                id="editMaxIncome"
                                onChange={(e) => {
                                  setIncomeEditValue({
                                    ...incomeEditValue,
                                    maxIncome: e.target.value.replace(
                                      /[^0-9]/g,
                                      ""
                                    ),
                                  });
                                }}
                                onBlur={editIncome.handleBlur}
                                value={editIncome.values.maxIncome}
                              />

                              {editIncome.touched.maxIncome &&
                              editIncome.errors.maxIncome ? (
                                <p className="error_text text-danger">
                                  {editIncome.errors.maxIncome}
                                </p>
                              ) : null}
                            </>
                          ) : (
                            <span>{data.max_income}</span>
                          )}
                        </td>

                        <td>
                          {incomeEdit === data.id ? (
                            <div>
                              <button
                                className="btn btn-primary"
                                type="button"
                                onClick={() => {
                                  editIncome.handleSubmit();
                                }}
                              >
                                Save
                              </button>{" "}
                              &nbsp;
                              <button
                                className="btn btn-primary"
                                type="button"
                                onClick={() => {
                                  setIncomeEdit(null);
                                }}
                              >
                                Cancel
                              </button>
                            </div>
                          ) : (
                            <div>
                              <button
                                disabled
                                className="btn btn-outline-primary"
                                type="button"
                                onClick={() => {
                                  setIncomeEdit(data.id);
                                  setIncomeEditValue({
                                    minIncome: data.min_income,
                                    maxIncome: data.max_income,
                                    id: data.id,
                                  });
                                }}
                              >
                                <i className="fa fa-pencil-square-o"></i>
                              </button>{" "}
                              &nbsp;
                              <button
                                disabled
                                className="btn btn-outline-danger"
                                onClick={() => {
                                  handleOpen();
                                  setDeleteId({ id: data.id, type: "income" });
                                }}
                                type="button"
                              >
                                <i className="fa fa-trash"></i>
                              </button>
                            </div>
                          )}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <td colSpan="7" className="nodatarow">
                      No Income Available for the Selected Provider
                    </td>
                  )}
                </tbody>
              </table>
            </div>
          </form>
        </div>
      </div>

      <div className="row"></div>
      <Modal
        show={show}
        onHide={() => {
          handleClose();
          // formik.resetForm();
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Delete {deleteId?.type === "score" ? "Score" : "Income"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Are you sure you want to delete{" "}
            {deleteId?.type === "score" ? "Score" : "Income"}?
          </p>

          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                handleClose();
                // formik.resetForm();
              }}
            >
              No
            </Button>
            <Button
              type="button"
              onClick={() =>
                deleteId?.type === "score" ? deleteScore() : deleteIncome()
              }
            >
              Yes
            </Button>
          </Modal.Footer>
          {/* </form> */}
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default Pricingmodel;
