import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

const SessionExpired = () => {
  const [show, setShow] = useState(true);

  const handleClose = () => {
    setShow(false);
  };

  const logoutUser = (e) => {
    sessionStorage.clear();
    window.close();
  };

  return (
    <Modal
      show={show}
    //   onHide={() => {
    //     handleClose();
    //     // formik.resetForm();
    //   }}
    >
      <Modal.Header>
        {/* <Modal.Title>Logout Decision Engine</Modal.Title> */}
        <div className="logoutDiv">
          <h4>Session Expired !!!</h4>
        </div>
      </Modal.Header>
      {/* <Modal.Body> */}

      <Modal.Footer>
        <Button
          type="button"
           onClick={logoutUser}
        >
          Close
        </Button>
      </Modal.Footer>
      {/* </Modal.Body> */}
    </Modal>
  );
};

export default SessionExpired;
