import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { AuthGet, AuthPost, AuthPut } from "../../common_var/httpService";
import { useStateValue } from "../../Context/StateProvider";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

export const TermsMapping = () => {
  const [{ settingid,version_id, pm_id }] = useStateValue();
  const [terms, setTerms] = useState();
  const [show, setShow] = useState(false);
  const [deleteRowId, setDeleteRowId] = useState(null);
  const handleClose = () => {
    setShow(false);
  };
  const handleOpen = () => {
    setShow(true);
  };

  const handleSelectEdit = (val) => {
    handleOpen();
  };

  const getTerms = async () => {
    await AuthGet(`api/term-mapping/get-all/${settingid}`)
      .then((res) => {
        if (res.status === 200) {
          setTerms(res);
        } else {
          toast.error(res.message, { duration: 4000 });
        }
      })
      .catch((err) => toast.error(err));
  };

  const TermsValidationsSchema = Yup.object({
    grade: Yup.string().required("*Grade is required"),
    terms: Yup.string().required("*Terms is required"),
    duration: Yup.number()
      .typeError("Duration must be a number")
      .required("Duration is required")
      .positive("Duration must be a positive number"),
  });

  const addTerms = useFormik({
    initialValues: {
      grade: "",
      terms: "",
      duration: "",
    },
    validationSchema: TermsValidationsSchema,
    onSubmit: async (values) => {
      if (values.grade === "default") {
        toast.error("Grades is required");
        return;
      }
      if (values.terms === "default") {
        toast.error("Terms is required");
        return;
      }
      let formdata = {
        term_duration: parseInt(values.duration),
        settingId: settingid,
        gradeId: parseInt(values.grade),
        termId: parseInt(values.terms),
        version_id:version_id,
        pm_id: pm_id
      };
      await AuthPost(`api/term-mapping/add`, formdata)
        .then((res) => {
          if (res?.status === 200) {
            toast.success(res.message, { duration: 4000 });
            addTerms.resetForm();
            getTerms();
          } else {
            toast.error(res.message, { duration: 4000 });
          }
        })
        .catch((err) => toast.error(err), { duration: 4000 });
    },
  });

  const inactivateTerm = async () => {
    await AuthPut(`api/term-mapping/inactive/${deleteRowId}`, {
      active: false,
    })
      .then((res) => {
        if (res.status === 200) {
          getTerms();
          toast.success(res.message, { duration: 4000 });
          handleClose();
        }
      })
      .catch((err) => {
        toast.error(err);
        handleClose();
      });
  };

  useEffect(() => {
    if (settingid) {
      getTerms();
    }
  }, [settingid]);

  return (
    <div className=" ">
      <div className="mb20 whiteBg">
        <form onSubmit={addTerms.handleSubmit}>
          <div className="row  align-items-center">
            <div className="col-md-3">
              <div className="form-group">
                <label for="ContactName">Grade</label>
                <select
                  className="form-select"
                  name="grade"
                  onChange={addTerms.handleChange}
                  onBlur={addTerms.handleBlur}
                  value={addTerms.values.grade}
                >
                  <option defaultValue value="default">
                    Select Grade
                  </option>
                  {terms?.grade?.map((terms) => (
                    <option value={terms?.id}>
                      {terms?.grade_description}
                    </option>
                  ))}
                </select>
                {addTerms.touched.grade && addTerms.errors.grade ? (
                  <span className="error_text text-danger">
                    {addTerms.errors.grade}
                  </span>
                ) : null}
              </div>
            </div>

            <div className="col-md-3">
              <div className="form-group">
                <label for="ContactName">Terms</label>
                <select
                  className="form-select"
                  name="terms"
                  onChange={addTerms.handleChange}
                  onBlur={addTerms.handleBlur}
                  value={addTerms.values.terms}
                >
                  <option defaultValue value="default">
                    Select terms
                  </option>
                  {terms?.terms?.map((terms) => (
                    <option value={terms?.id}>{terms?.term_description}</option>
                  ))}
                </select>
                {addTerms.touched.terms && addTerms.errors.terms ? (
                  <span className="error_text text-danger">
                    {addTerms.errors.terms}
                  </span>
                ) : null}
              </div>
            </div>

            <div className="col-md-3">
              <div className="form-group">
                <label for="duration">Duration (Months)</label>
                <input
                  name="duration"
                  className="form-control"
                  placeholder="Enter Duration"
                  id="duration"
                  onChange={(e) => {
                    addTerms.setFieldValue(
                      "duration",
                      e.target.value.replace(/[^\d]/g, "")
                    );
                  }}
                  // onChange={addTerms.handleChange}
                  onBlur={addTerms.handleBlur}
                  value={addTerms.values.duration}
                />
                {addTerms.touched.duration && addTerms.errors.duration ? (
                  <span className="error_text text-danger">
                    {addTerms.errors.duration}
                  </span>
                ) : null}
              </div>
            </div>

            <div className="col-md-2">
              <button type="submit" className="btn btn-success">
                ADD
              </button>
            </div>
          </div>
        </form>
      </div>

      <div className="row">
        <div className="col-12">
          <form name="form">
            <div className="tableResponsive">
              <table className="table mainTable">
                <thead>
                  <tr>
                    <th style={{ width: "10%" }}>S.No</th>
                    <th style={{ width: "20%" }}>Grade</th>
                    <th style={{ width: "20%" }}>Term</th>
                    <th style={{ width: "20%" }}>Duration</th>
                    <th style={{ width: "30%" }}>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {terms?.termMapping?.length > 0 ? (
                    terms?.termMapping?.map((data, index) => (
                      <tr key={data?.id}>
                        <td>{index + 1}</td>
                        <td>
                          <span>{data?.grade_description}</span>
                        </td>
                        <td>
                          <span>{data?.term_description}</span>
                        </td>
                        <td>
                          <span>{data?.term_duration}</span>
                        </td>

                        <td>
                          <button
                            className="btn btn-outline-danger"
                            type="button"
                            onClick={() => {
                              setShow(true);
                              setDeleteRowId(data.term_grade_id);
                            }}
                          >
                            <i className="fa fa-trash"></i>
                          </button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <td colSpan="5" className="nodatarow">
                      No Terms Available for the Selected Provider
                    </td>
                  )}
                </tbody>
              </table>
            </div>
          </form>
        </div>
      </div>
      <Modal
        show={show}
        onHide={() => {
          handleClose();
          // formik.resetForm();
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Term Mapping</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete?</p>

          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                handleClose();
                // formik.resetForm();
              }}
            >
              No
            </Button>
            <Button type="button" onClick={() => inactivateTerm()}>
              Yes
            </Button>
          </Modal.Footer>
          {/* </form> */}
        </Modal.Body>
        {/* inactivateTerm(data?.term_grade_id) */}
      </Modal>
    </div>
  );
};
