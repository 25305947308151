import { useRoutes, useLocation, useSearchParams } from "react-router-dom";
import { useStateValue } from "./Context/StateProvider";
import Admin from "./Decision/Admin.jsx";
import "./styles.scss";
import Toast from "./Components/Toaster/Toast";
import { useEffect } from "react";
import { Unauthorized } from "./Decision/Pages/Unauthorized";
import { Authorize } from "./Decision/Pages/Authorize";
import SessionExpired from "./Decision/Pages/SessionExpired";

function App() {
  const routes = useRoutes([
    { path: "/", element: <Authorize /> },
    { path: "/unauthorized", element: <Unauthorized /> },
    { path: "/sessionexpired", element: <SessionExpired /> },
    // {
    //   element: <Guard />,
    //   children: [
    //     { path: "/pricingdatabase", element: <Pricingdatabase /> },
    //     { path: "/offeredproduct", element: <Offeredproduct /> },
    //     { path: "/pricingmodel", element: <Pricingmodel /> },
    //     { path: "/productvalue", element: <Productvalue /> },
    //     { path: "/sandbox", element: <Sandbox /> },
    //     { path: "/termmodel", element: <Tenor /> },
    //     { path: "/termsmapping", element: <TermsMapping /> },
    //     { path: "/deconfig", element: <Deconfig /> },
    //     { path: "/ruledatabase", element: <Ruledatabase /> },
    //     { path: "/offersmaping", element: <OfferMapping /> },
    //     { path: "/grade", element: <Grade /> },
    //     { path: "/", element: <Ruledatabase /> }
    //   ]
    // }
  ]);
  const value = useStateValue();

  const queryParams = useLocation();
  // useEffect(() => {
  //   if (queryParams?.pathname.includes("onboarding")) {
  //     document.body.classList.add("landing-background");
  //   } else {
  //     document.body.classList.remove("landing-background");
  //   }
  // }, []);

  return (
    <>
      {queryParams.pathname === "/unauthorized" ? (
        <Unauthorized />
      ) : queryParams.pathname === "/" ? (
        <Authorize />
      ) : queryParams.pathname === "/sessionexpired" ? (
        <SessionExpired />
      ) : (
        <>
          <Admin />
          <Toast />
        </>
      )}
    </>
  );
}

export default App;
