import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { AuthGet, AuthPost, AuthPut, Put } from "../../common_var/httpService";
import { useStateValue } from "../../Context/StateProvider";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

function Offeredproduct() {
  const [initialState] = useStateValue();
  const token = sessionStorage.getItem("token");
  const [data, setdata] = useState([]);
  const [editedRowId, setEditedRowId] = useState(null);
  const [editedValue, setEditedValue] = useState("");
  const [show, setShow] = useState(false);
  const [deleteRowId, setDeleteRowId] = useState(null);

  useEffect(() => {
    if (initialState?.settingid !== "") {
      getofferproductList();
    }
  }, [initialState]);

  const formik = useFormik({
    initialValues: {
      offerLabel: "",
      settingId: initialState?.settingid?.setting_id,
    },

    validationSchema: Yup.object({
      offerLabel: Yup.string().required("*Offer name is required"),
    }),
    onSubmit: async (values) => {
      let sendData = {
        offerLabel: values.offerLabel,
        settingId: initialState?.settingid,
        version_id:initialState?.version_id,
        pm_id: initialState?.pm_id
      };
      await AuthPost(`api/offer-products/add-offer`, sendData)
        .then((res) => {
          console.log("sendData", sendData);
          if (res?.status === 200 || res?.status === 201) {
            formik.resetForm();
            toast.success("Offer Name added successfully", { duration: 4000 });
            getofferproductList();
          } else {
            toast.error(res.message, { duration: 4000 });
          }
        })
        .catch((err) => {
          console.log("Error: ", err);
          toast.error(err.message, { duration: 4000 });
        });
    },
  });

  const formikedit = useFormik({
    initialValues: {
      offer_label: editedValue,
    },
    validationSchema: Yup.object({
      offer_label: Yup.string().required("*Offer name is required"),
    }),

    onSubmit: async (values) => {
      console.log("editedValue::: ", editedValue);
      if (editedValue === "") toast.error("Please Enter A Offer Name");
      else {
        let sendData = {
          offerLabel: editedValue,
          settingId: initialState?.settingid,
        };
        await AuthPut(
          `api/offer-products/update-offer/${editedRowId}`,
          sendData
        )
          .then((res) => {
            if (res?.status === 200 || res?.status === 201) {
              formikedit.setValues(res?.editedValue);
              toast.success("Offer Name updated successfully", {
                duration: 4000,
              });
              setEditedRowId(null);
              getofferproductList();
              formikedit.resetForm();
            } else {
              toast.error(res.message, { duration: 4000 });
            }
          })
          .catch((err) => {
            console.log("Error: ", err);
            toast.error(err.message, { duration: 4000 });
          });
      }
    },
  });

  const getofferproductList = async () => {
    await AuthGet(
      `api/offer-products/get-all-offers/${initialState?.settingid}`,
      token
    )
      .then((res) => {
        if (res?.status === 200) {
          setdata(res?.data);
        }
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  const gradeDelete = async () => {
    console.log("Delete Id: ", deleteRowId);
    await AuthPut(`api/offer-products/offer-inactive/${deleteRowId}`, {
      active: false,
      settingId: initialState?.settingid,
    })
      .then((res) => {
        if (res?.status === 200 || res?.status === 201) {
          getofferproductList();
          handleClose();
          toast.success("Offer product deleted successfully", {
            duration: 4000,
          });
        } else {
          toast.error(res.message);
          handleClose();
        }
      })
      .catch((err) => {
        toast.error(err.message);
        handleClose();
      });
  };

  const handleClose = () => {
    setShow(false);
  };
  const handleOpen = () => {
    setShow(true);
  };

  const handleSelectEdit = (val) => {
    handleOpen();
  };

  return (
    <div className=" ">
      <div>
        <div className="mb20 whiteBg">
          <form>
            <div className=" ">
              <div className="row align-items-center">
                <div className="col-md-2 ">
                  {" "}
                  <label for="ContactName">Offer Name</label>
                </div>
                <div className="col-md-2 mobSpace_mb12">
                  <input
                    name="offerLabel"
                    placeholder="Enter Offered Product"
                    formControlName="offerLabel"
                    id="offerLabel"
                    onChange={(e) => {
                      formik.setFieldValue(
                        "offerLabel",
                        e.target.value
                          .replace(/[^a-zA-Z0-9-\s]/g, "")
                          .replace(/^0+/g, "")
                          .replace(/\.(\d{0,2}).*/g, ".$1")
                      );
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.offerLabel}
                    className="form-control"
                  />
                  {formik.touched.offerLabel && formik.errors.offerLabel ? (
                    <p className="error_text text-danger">
                      {formik.errors.offerLabel}
                    </p>
                  ) : null}
                </div>
                <div className="col-md-2 ">
                  {" "}
                  <button
                    className="btn btn-success  "
                    type="submit"
                    onClick={formik.handleSubmit}
                  >
                    <div className=" "> ADD </div>
                  </button>{" "}
                </div>
              </div>
            </div>
          </form>
        </div>
        <div className="row">
          <div className="col-12">
            <form name="form">
              <div className="tableResponsive">
                <table className="table mainTable">
                  <thead>
                    <tr>
                      <th style={{ width: "10%" }}>S.No</th>
                      <th style={{ width: "70%" }}>Offer Name</th>
                      <th style={{ width: "20%" }}>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.length > 0 ? (
                      data?.map((data, index) => (
                        <tr key={data.id}>
                          <td scope="row">{index + 1}</td>

                          <td>
                            {editedRowId === data.id ? (
                              <form onSubmit={formikedit.handleSubmit}>
                                <div>
                                  <input
                                    type="text"
                                    name="offer_label"
                                    className="form-control"
                                    placeholder="Offerd Product"
                                    value={editedValue}
                                    // onChange={(e) => setEditedValue(e.target.value)}
                                    onChange={(e) => {
                                      setEditedValue(
                                        e.target.value
                                          .replace(/[^a-zA-Z0-9-\s]/g, "")
                                          .replace(/^0+/g, "")
                                          .replace(/\.(\d{0,2}).*/g, ".$1")
                                      );
                                      formikedit.setFieldValue(
                                        "offer_label",
                                        e.target.value
                                      );
                                    }}
                                  />
                                  {formikedit.touched.offer_label &&
                                    formikedit.errors.offer_label && (
                                      <span className="error_text">
                                        {formikedit.errors.offer_label}
                                      </span>
                                    )}
                                </div>
                              </form>
                            ) : (
                              <span>{data.offer_label}</span>
                            )}
                          </td>

                          <td>
                            {editedRowId === data.id ? (
                              <div>
                                <button
                                  className="btn btn-primary"
                                  type="button"
                                  style={{
                                    marginRight: "10px",
                                  }}
                                  onClick={formikedit.handleSubmit}
                                >
                                  Save
                                </button>
                                <button
                                  className="btn btn-primary"
                                  type="button"
                                  onClick={() => {
                                    setEditedRowId(null);
                                    formikedit.resetForm();
                                  }}
                                >
                                  Cancel
                                </button>
                              </div>
                            ) : (
                              <div>
                                <button
                                  disabled
                                  className="btn btn-outline-primary"
                                  type="button"
                                  style={{
                                    marginRight: "10px",
                                  }}
                                  onClick={() => {
                                    setEditedRowId(data.id);
                                    setEditedValue(data.offer_label);
                                    // formikedit.setValues(data.offer_label);
                                  }}
                                >
                                  <i class="fa fa-pencil-square-o"></i>
                                </button>

                                <button
                                  disabled
                                  className="btn btn-outline-danger"
                                  type="button"
                                  onClick={() => {
                                    setShow(true);
                                    setDeleteRowId(data.id);
                                  }}
                                >
                                  <i class="fa fa-trash"></i>
                                </button>
                              </div>
                            )}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <td colSpan="7" className="nodatarow">
                        No Offered Product Available for the Selected Provider
                      </td>
                    )}
                  </tbody>
                </table>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Modal
        show={show}
        onHide={() => {
          handleClose();
          // formik.resetForm();
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete Offer Product</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete offer product?</p>

          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                handleClose();
                // formik.resetForm();
              }}
            >
              No
            </Button>
            <Button type="button" onClick={() => gradeDelete()}>
              Yes
            </Button>
          </Modal.Footer>
          {/* </form> */}
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default Offeredproduct;
