// import React from "react";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import {
  AuthGet,
  AuthPost,
  geturl,
  InitialGet,
  Post,
} from "../../common_var/httpService";
import { useStateValue } from "../../Context/StateProvider";
import * as Yup from "yup";
import { useFormik } from "formik";

const MapProvider = () => {
  const [initialState] = useStateValue();
  const token = sessionStorage.getItem("token");
  const [setting, setsetting] = useState([]);
  const [{}, dispatch] = useStateValue();
  const [doctype, setdoctype] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [version, setVersion] = useState([]);

  const getseetingList = async (token) => {
    const admin_id = sessionStorage.getItem("admin_id");
    await AuthGet(`api/rules-config/get-all-settings/${admin_id}`, token)
      .then((res) => {
        if (res?.status === 200) {
          let lastData = res?.data?.length - 1;
          setsetting(res?.data);
          setStateList(res?.state);
          setVersion(res?.version);
          sessionStorage.setItem("version_id", res?.version[0]?.version_id);
          dispatch({
            type: "SET_SETTING_ID",
            payload: res?.data[0]?.id,
          });
          dispatch({
            type: "SET_PM_ID",
            payload: res?.data[lastData]?.pm_id,
          });
          dispatch({
            type: "SET_VERSION_ID",
            payload: res?.version[0]?.version_id,
          });
        }
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  const handleSetSetting = (e) => {
    setdoctype(e?.id);
    dispatch({
      type: "SET_SETTING_ID",
      payload: e?.id,
    });
    dispatch({
      type: "SET_PM_ID",
      payload: e?.pm_id,
    });
  };

  const handleVersion = (val) => {
    formik.setFieldValue("version", val.target.value);
    dispatch({
      type: "SET_VERSION_ID",
      payload: val.target.value,
    });
  };

  const handleState = (val) => {
    dispatch({ type: "SET_STATECODE", payload: val.target.value });
    formik.setFieldValue("stateCode", val.target.value);
  };

  const validationSchema = Yup.object({
    stateCode: Yup.string().required("*State is required"),
  });

  const formik = useFormik({
    initialValues: {
      stateCode: stateList,
      version: version,
    },
    // validationSchema: validationSchema,
  });

  useEffect(() => {
    getseetingList(token);
  }, [token]);

  return (
    <div className="whiteBg mb20">
      <div className=" ">
        <div className="row AlignItemCenter">
          {/* version input start */}
          <div className="col-md-1 ">
            <label for="stateCode" className="nowrap">
              Select Version
            </label>
          </div>

          <div className="col-md-3 ">
            <select
              class="form-select"
              formControlName="stateCode"
              name="stateCode"
              id="stateCode"
              // onChange={formik.handleChange}
              onChange={(newValue) => handleVersion(newValue)}
              onBlur={formik.handleBlur}
              value={formik.values.version}
            >
              {version?.map((e) => (
                <option value={e?.version_id}>{e?.version}</option>
              ))}
            </select>
            {/* {formik.touched.stateCode && formik.errors.stateCode ? (
              <p className="error_text text-danger">
                {formik.errors.stateCode}
              </p>
            ) : null} */}
          </div>
          {/* version input end */}

          {/* state input start */}
          <div className="col-md-1 ">
            <label for="stateCode" className="nowrap">
              Select State
            </label>
          </div>

          <div className="col-md-3 ">
            <select
              class="form-select"
              formControlName="stateCode"
              name="stateCode"
              id="stateCode"
              onChange={(newValue) => handleState(newValue)}
              // onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.stateCode}
            >
              {stateList?.map((data) => {
                const { state, short_desc } = data;
                return <option value={short_desc}>{state}</option>;
              })}
            </select>
            {formik.touched.stateCode && formik.errors.stateCode ? (
              <p className="error_text text-danger">
                {formik.errors.stateCode}
              </p>
            ) : null}
          </div>
          {/* <div className=" col-md-2">
            <div className="switch">
              <input
                type="checkbox"
                id="stateToggle"
                className="mt20"
                // checked={facvalue}
                // onChange={(e) => {
                //   //  console.log("e---->", e.target.checked);
                //   updateTwoFac(e);
                // }}
              />
              <label htmlFor="stateToggle" />
            </div>
          </div> */}
          {/* state input end */}

          {/* map provider input start */}
          <div className="col-md-1 ">
            <label for="settingRule" className="nowrap">
              Map Provider
            </label>
          </div>
          <div className="col-md-3 ">
            <select
              value={doctype}
              className="form-select"
              name="settingRule"
              onChange={(event) => {
                const selectedSettingId = event.target.value;
                const selectedSetting = setting.find(
                  (setting) => setting.id === selectedSettingId
                );
                handleSetSetting(selectedSetting);
              }}
            >
              {setting?.map((setting) => (
                <option key={setting.id} value={setting.id}>
                  {setting.provider_name}
                </option>
              ))}
            </select>
          </div>
          {/* map provider ends */}
        </div>
      </div>
    </div>
  );
};

export default MapProvider;
