import { env_variables } from "./env";
// import httpClient from 'react-http-client';
// let httpClient = require("./httpClient");
import HttpHandler from "./httpHandler";
// let token = (sessionStorage.getItem("userData"));
// let optionalHeaders = {
//   Authorization: token ? `Bearer ${token?.token}` : "",
// };
let token = sessionStorage.getItem("token");
console.log('token inside https::: ', token);

export const geturl = (url) => {
  console.log("Process env: ", process.env.REACT_APP_DE_API_URL);
  return `${process.env.REACT_APP_DE_API_URL}/${url}`;
};

export const AuthPost = async (url, body) => {
  token = sessionStorage.getItem("token");
  let optionalHeaders = {
    Authorization: `Bearer ${token}`,
  };

  let activeUrl = geturl(url);
  let data = await HttpHandler.post(activeUrl, body, optionalHeaders);
  return data;
};

export const AuthPatch = async (url, body) => {
  let token;
  token = sessionStorage.getItem("token");

  let optionalHeaders = {
    Authorization: token ? `Bearer ${token}` : "",
  };

  let activeUrl = geturl(url);
  let data = await HttpHandler.patch(activeUrl, body, optionalHeaders);
  // console.log(data);
  return data;
};

export const AuthGet = async (url) => {
  token = sessionStorage.getItem("token");
  let optionalHeaders = {
    Authorization: `Bearer ${token}`,
  };
  let activeUrl = geturl(url);
  let data = await HttpHandler.get(activeUrl, "", optionalHeaders);
  return data;
};

 export const InitialGet = async (url,jwt) => {
  let optionalHeaders = {
    Authorization: `Bearer ${jwt}`,
  };
  let activeUrl = geturl(url);
  let data = await HttpHandler.get(activeUrl, "", optionalHeaders);
  console.log('data::: ', data);
  return data;
};

export const Get = async (url) => {
  let activeUrl = geturl(url);
  let data = await HttpHandler.get(activeUrl, "", {});
  return data;
};

export const Post = async (url, body) => {
  let activeUrl = geturl(url);
  let data = await HttpHandler.post(activeUrl, body);
  return data;
};

export const AuthPut = async (url,body) => {
  token = sessionStorage.getItem("token");

  let optionalHeaders = {
    Authorization: token ? `Bearer ${token}` : "",
  };
  console.log("Put token:::", optionalHeaders);
  let activeUrl = geturl(url);
  let data = await HttpHandler.put(activeUrl, body, optionalHeaders);
  // console.log(data);
  return data;
};

export const AuthFile = async (url, body, key, method = "post") => {
  let token;
  // if (key == "admin") {
  //   token = sessionStorage.getItem("admin_token");
  // } else if (key == "borrower") {
  //   token = sessionStorage.getItem("borrower_token");
  // }
  token = sessionStorage.getItem("token");

  let activeUrl = geturl(url, key);
  if (method === "post") {
    let data = await HttpHandler.post(activeUrl, body, {
      "content-type": "multipart/form-data",
      Authorization: token ? `Bearer ${token}` : "",
    });
    return data;
  } else if (method === "patch") {
    let data = await HttpHandler.patch(activeUrl, body, {
      headers: {
        "content-type": "multipart/form-data",
      },
    });
    return data;
  }
};
