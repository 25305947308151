import React, { useEffect, useRef, useState } from "react";
import { Outlet, useLocation, useRoutes } from "react-router-dom";
import Adminsidebar from "../Components/sidebar/adminsidebar";
import logo from "./../assets/images/logo.svg";
import menuicon from "./../assets/images/menu-icon.svg";
import logouticon from "./../assets/images/logout-icon.svg";
import { useNavigate, useSearchParams } from "react-router-dom";
import footerlogo from "./../assets/images/whitelogo.svg";
import Dropdown from "react-bootstrap/Dropdown";
import UserProfilelogo from "./../assets/images/userProfile.svg";
import NotificationIcon from "./../assets/images/notification_icon.svg";
import MenuhorizontalIcon from "./../assets/images/menu_horizontal_icon.svg";
import CloseToggleIcon from "./../assets/images/closeToggleIcon.svg";
import Pricingdatabase from "./Pages/Pricingdatabase";
import { Grade } from "./Pages/grade";
import Offeredproduct from "./Pages/offeredproduct";
import { OfferMapping } from "./Pages/Offer-Mapping";
import Pricingmodel from "./Pages/pricingmodel";
import Productvalue from "./Pages/productvalue";
import Sandbox from "./Pages/sandbox";
import Ruledatabase from "./Pages/Ruledatabase";
import { TermsMapping } from "./Pages/Terms-Mapping";
import Tenor from "./Pages/tenor";
import Deconfig from "./Pages/deconfig";
import MapProvider from "./Components/MapProvider";
import { useStateValue } from "../Context/StateProvider";
import Guard from "../Components/Guard/Guard";
import { Unauthorized } from "./Pages/Unauthorized";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

const Admin = () => {
  // Expand and Responsive
  const [toggleIcon, setToggleIcon] = useState(false);
  const tabContentRef = useRef(null);
  const queryParams = useLocation();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);

  // const [queryParameters] = useSearchParams();
  // console.log("Query Params:::", queryParameters);
  // const admin_id = queryParameters.get("tid");
  // const urlToken = queryParameters.get("t");
  const [{}, dispatch] = useStateValue();
  // const [settingData, setSetting] = useState([]);

  const handleClose = () => {
    setShow(false);
  };
  const handleOpen = () => {
    setShow(true);
  };

  const logout = async () => {
    navigate("/admin/login");
    sessionStorage.clear();
  };

  const routes = useRoutes([
    // { path: "/unauthorized", element: <Unauthorized /> },
    {
      element: <Guard />,
      children: [
        { path: "/pricingdatabase", element: <Pricingdatabase /> },
        { path: "/offeredproduct", element: <Offeredproduct /> },
        { path: "/pricingmodel", element: <Pricingmodel /> },
        { path: "/productvalue", element: <Productvalue /> },
        { path: "/sandbox", element: <Sandbox /> },
        { path: "/termmodel", element: <Tenor /> },
        { path: "/termsmapping", element: <TermsMapping /> },
        { path: "/deconfig", element: <Deconfig /> },
        { path: "/ruledatabase", element: <Ruledatabase /> },
        { path: "/offersmaping", element: <OfferMapping /> },
        { path: "/grade", element: <Grade /> },
        // { path: "/", element: <Ruledatabase /> }
      ],
    },
  ]);
  const logoutUser = (e) => {
    sessionStorage.clear();
    window.close();
  };

  // useEffect(() => {
  //   // setSetting('hjkk')
  //   console.log("Inside Use Effect");
  //   if(admin_id !== null && urlToken !== null) {
  //     const primaryToken = urlToken.split("-")[1];
  //     const reqBody = {
  //       url: "api/auth/login",
  //       data: {
  //         admin_id,
  //         token: primaryToken,
  //       },
  //     };
  //     Post(reqBody.url, reqBody.data)
  //       .then((res) => {
  //         console.log("Login Res:::", res);
  //         if (res.status === 200) {
  //           sessionStorage.setItem("token", res.jwtAccessToken);
  //           sessionStorage.setItem("admin_id", admin_id);
  //           console.log("Token Set");
  //           navigate("/ruledatabase");
  //         }
  //       })
  //       .catch((err) => {
  //         console.log(err)
  //       });
  //   }
  // }, []);
  useEffect(() => {
    if(routes === null){
      navigate("/unauthorized")
    }
    window.scrollTo(0, 0);
  }, [queryParams.pathname]);
  return (
    <div className="bluebackground">
      <div className="logoutblock">
        <div className="logosection">
          <div className="displayFlex">
            <button
              className="emptyButton"
              onClick={() => setToggleIcon((prev) => !prev)}
            >
              {toggleIcon && true ? (
                <img
                  src={CloseToggleIcon}
                  alt="CloseToggleIcon"
                  className="mobileMenuicon"
                />
              ) : (
                <img src={menuicon} className="mobileMenuicon" />
              )}
            </button>
            <img src={footerlogo} className="mainLogo" />
          </div>
        </div>
        <div className="header">
          <div>
            {queryParams.pathname === "/pricingdatabase"
              ? "Pricing Model Mapping"
              : queryParams.pathname === "/offeredproduct"
              ? "Offered Product"
              : queryParams.pathname === "/productvalue"
              ? "Product Value"
              : queryParams.pathname === "/sandbox"
              ? "Run in Sandbox"
              : queryParams.pathname === "/termmodel"
              ? "Term Model"
              : queryParams.pathname === "/termsmapping"
              ? "Terms Mapping"
              : queryParams.pathname === "/deconfig"
              ? "DE Config"
              : queryParams.pathname === "/ruledatabase"
              ? "Rule Database"
              : queryParams.pathname === "/offersmaping"
              ? "Offers Mapping"
              : queryParams.pathname === "/grade"
              ? "Grade"
              : queryParams.pathname === "/pricingmodel"
              ? "Pricing Model"
              : queryParams.pathname === "/"
              ? "Rule Database"
              : null}
          </div>
          <div>
            <i
              onClick={handleOpen}
              className="cursorpointer fa fa-sign-out"
            ></i>
          </div>
        </div>
      </div>
      <div
        className={
          queryParams.pathname !== "/admin/login" && toggleIcon
            ? "page_wrapper show"
            : "page_wrapper"
        }
      >
        {queryParams.pathname !== "/admin/login" && (
          <>
            <Adminsidebar
              setToggleIcon={setToggleIcon}
              toggleIcon={toggleIcon}
            />
          </>
        )}
        <div
          className={
            queryParams.pathname !== "/admin/login" && "content_wrapper"
          }
        >
          <MapProvider />
          <main>{routes}</main>
        </div>
      </div>
      <Modal
        show={show}
        onHide={() => {
          handleClose();
          // formik.resetForm();
        }}
      >
        <Modal.Header>
          {/* <Modal.Title>Logout Decision Engine</Modal.Title> */}
          <div className="logoutDiv">
            <h4>Are you sure you want to logout of decision engine?</h4>
            <h6>Unsaved changes will be lost</h6>
          </div>
        </Modal.Header>
        {/* <Modal.Body> */}

        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              handleClose();
              // formik.resetForm();
            }}
          >
            No
          </Button>
          <Button type="button" onClick={logoutUser}>
            Yes
          </Button>
        </Modal.Footer>
        {/* </Modal.Body> */}
      </Modal>
    </div>
  );
};

export default Admin;
