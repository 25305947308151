export const initialState = {
  formData: {},
  errors: {},
  authData: {},
  formSubmitted: false,
  cosignerCheckbox: false,
  showToast: false,
  toastMessage: "",
  settingid: "",
  stateCode: "",
  version_id: "",
  toastType: "",
  offers: [],
  loanId: "",
  globalStep: null,
  currentStep: null,
  signature: null,
  selectLoanPopup: false,
  emailPhonePopup: false,
  pm_id: null,
};

export const reducer = (state, action) => {
  switch (action.type) {
    case "SET_VALUES":
      let data = action.payload;
      return {
        ...state,
        formData: { ...state.formData, ...data },
      };
    case "SET_SETTING_ID":
      let data1 = action.payload;
      return {
        ...state,
        settingid: data1,
        // settingid: action.payload,
      };

    case "SET_VERSION_ID":
      let versionData = action.payload;
      return {
        ...state,
        version_id: versionData,
      };

    case "SET_STATECODE":
      let Selectedstate = action.payload;
      return {
        ...state,
        stateCode: Selectedstate,
      };

    case "SET_AUTH":
      let authdata = action.payload;
      return {
        ...state,
        authData: { ...state.authData, ...authdata },
      };

    case "SET_ERRORS":
      let err = action.payload;
      return {
        ...state,
        errors: { ...state.errors, ...err },
      };

    case "REMOVE_ERROR":
      delete state.errors[action.payload];
      return {
        ...state,
      };

    case "CLEAR_VALUES":
      return {
        ...state,
        formData: {},
      };

    case "FORM_SUBMIT":
      return {
        ...state,
        formSubmitted: action.payload,
      };

    case "SET_COSIGNER":
      return {
        ...state,
        cosignerCheckbox: action.payload,
      };
    case "TOAST":
      return {
        ...state,
        showToast: true,
        toastMessage: action.toastMessage,
        toastType: action.toastType,
      };

    case "LOAN_ID":
      return {
        ...state,
        loanId: action.payload,
      };

    case "SET_OFFERS":
      return {
        ...state,
        offers: action.payload,
      };
    case "SET_SIGN":
      return {
        ...state,
        signature: action.payload,
      };

    case "SET_STEP":
      return {
        ...state,
        globalStep: action.payload,
      };

    case "SET_CURRENT_STEP":
      return {
        ...state,
        currentStep: action.payload,
      };

    case "CLEAR_ERRORS":
      return {
        ...state,
        errors: {},
      };

    case "SET_LOANAMOUNT_POPUP":
      return {
        ...state,
        selectLoanPopup: action.payload,
      };

    case "SET_EMAILPHONE_POPUP":
      return {
        ...state,
        emailPhonePopup: action.payload,
      };

    case "SET_PM_ID":
      return {
        ...state,
        pm_id: action.payload,
      };

    default:
      return state;
  }
};
