import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Post } from "../../common_var/httpService";

export const Authorize = () => {
  const [queryParameters] = useSearchParams();
  const admin_id = queryParameters.get("tid");
  const urlToken = queryParameters.get("t");
  console.log("urlToken::: ", urlToken);
  const navigate = useNavigate();

  useEffect(() => {
    console.log("Inside Use Effect");
    if (admin_id !== null && urlToken !== null) {
      const primaryToken = urlToken.split("-")[1];
      const reqBody = {
        url: "api/auth/login",
        data: {
          admin_id,
          token: primaryToken,
        },
      };
      Post(reqBody.url, reqBody.data)
        .then((res) => {
          console.log("Login Res:::", res);
          if (res.status === 200) {
            sessionStorage.setItem("token", res.jwtAccessToken);
            sessionStorage.setItem("admin_id", admin_id);
            console.log("Token Set");
            navigate("/ruledatabase");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (urlToken === null) {
      navigate("/unauthorized");
    }
  }, []);

  return <></>;
};
