import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { AuthGet, AuthPut, InitialGet } from "../../common_var/httpService";
import * as Yup from "yup";
import { useStateValue } from "../../Context/StateProvider";

function Ruledatabase() {
  const token = sessionStorage.getItem("token");
  const [data, setdata] = useState([]);
  const [show, setShow] = useState(false);
  const [edit, setIsEdit] = useState([]);
  const [{ settingid, version_id, stateCode }] = useStateValue();
  const handleClose = () => {
    setShow(false);
  };
  const handleOpen = () => {
    setShow(true);
  };

  const getRuleList = async () => {
    await InitialGet(`api/rules-config/get-all-rules/${settingid}`, token)
      .then((res) => {
        if (res?.status === 200) {
          setdata(res?.data);
        }
      })
      .catch((err) => toast.error(err));
  };

  const formik = useFormik({
    initialValues: {
      declined: "",
      dec_val: "",
      dec_boolean: "",
      pass_through: "",
    },
    validationSchema: Yup.object({
      declined: Yup.string().required("*Declined is required"),
      dec_val: Yup.number()
        .typeError("Value must be a number")
        .required("Value is required")
        .positive("Value must be a positive number"),
      dec_boolean: Yup.boolean().required("*Declined State is required"),
      pass_through: Yup.boolean().required("*Pass Through is required"),
    }),
    onSubmit: async (values) => {
      let formData = {
        decline_if: values.declined,
        value: Number(values.dec_val),
        pass_through:
          values.pass_through === "true"
            ? true
            : (values.pass_through = "false" ? false : values.pass_through),
        disabled:
          values.dec_boolean === "true"
            ? true
            : values.dec_boolean === "false"
            ? false
            : values.dec_boolean,
        settingId: settingid,
        version_id: version_id,
      };

      await AuthPut(`api/rules-config/edit-rule/${edit?.id}`, formData)
        .then((res) => {
          if (res.status == 200) {
            toast.success("Rules updated successfully");
            formik.resetForm();
            getRuleList();
          }
          if (res.status === 400) {
            toast.error("Bad Request");
          }
          handleClose();
        })
        .catch((err) => {
          console.log(err);
          toast.error("Bad Request");
          handleClose();
        });
      values.declined = "";
      values.dec_val = "";
      values.dec_boolean = "";
      values.pass_through = "";
      setIsEdit([]);
    },
  });
  const handleSelectEdit = (val) => {
    setIsEdit(val);
    formik.setValues({
      declined: val.declineif,
      pass_through: val.pass_through,
      dec_boolean: val.disabled,
      dec_val: val.value,
    });
    handleOpen();
  };

  useEffect(() => {
    if (settingid) {
      getRuleList();
    }
  }, [settingid,stateCode]);
  return (
    <>
      <div className=" ">
        <div className="displayFlex spaceBetween productHeader">
          <div className=" ">
            <strong> Product Rules </strong>
          </div>
          <div>
            <span className="redcolor">
              (Note : "S* - Incremental Stages or Types of Rules")
            </span>
          </div>
        </div>
        <div className="tableResponsive">
          <table className="table mainTable">
            <thead>
              <tr>
                <th>Id</th>
                <th>Rule Description</th>
                <th>Declined If</th>
                <th>Active</th>
                <th>Bypass</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {data.map((el) => (
                <>
                  <tr>
                    <td>{el?.short_desc}</td>
                    <td>{el?.description}</td>
                    <td>
                      {el?.declineif === "lt"
                        ? `Lesser Than ${el?.value}`
                        : el?.declineif === "gt"
                        ? `Greater Than ${el?.value}`
                        : el?.declineif === "gte"
                        ? `Greater Than and Equal To ${el?.value}`
                        : el?.declineif === "lte"
                        ? `Lesser Than and Equal To ${el?.value}`
                        : ""}
                    </td>
                    <td>
                      {!el?.disabled ? (
                        <div className="trueBtn">true</div>
                      ) : (
                        <div className="falseButto">false</div>
                      )}
                    </td>
                    <td>
                      {el?.pass_through ? (
                        <div className="trueBtn">true</div>
                      ) : (
                        <div className="falseButto">false</div>
                      )}
                    </td>
                    <td>
                      <div
                        onClick={() => handleSelectEdit(el)}
                        className="btn btn-outline-primary"
                      >
                        <i class="fa fa-pencil-square-o"></i>
                      </div>
                    </td>
                  </tr>
                </>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <Modal
        show={show}
        onHide={() => {
          handleClose();
          formik.resetForm();
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>{edit.description}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={formik.handleSubmit}>
            <div className="row">
              <div className="col-md-6">
                <div className="mb20px">
                  <label htmlFor="declined">Declined If</label>
                  <select
                    name="declined"
                    id="declined"
                    className="form-select"
                    value={formik.values.declined}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  >
                    <option value="gt">Greater Than</option>
                    <option value="gte">Greater Than, Equal to</option>
                    <option value="lt">Less Than</option>
                    <option value="lte">Less Than, Equal to</option>
                  </select>

                  {formik.touched.declined && formik.errors.declined ? (
                    <p className="error_text">{formik.errors.declined}</p>
                  ) : null}
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb20px">
                  <label htmlFor="dec_val">{edit.dec_val}Value</label>
                  <input
                    type="text"
                    id="dec_val"
                    name="dec_val"
                    class="form-control"
                    onChange={(e) => {
                      formik.setFieldValue(
                        "dec_val",
                        e.target.value
                          .replace(/[^\d\.]/g, "")
                          .replace(/^0+/g, "")
                          .replace(/\.(\d{0,2}).*/g, ".$1")
                      );
                    }}
                    // onChange={formik.handleChange}
                    value={formik.values.dec_val}
                    onBlur={formik.handleBlur}
                    placeholder="Value"
                    maxLength={10}
                  />
                  {formik.touched.dec_val && formik.errors.dec_val ? (
                    <p className="error_text">{formik.errors.dec_val}</p>
                  ) : null}
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb20px">
                  <label htmlFor="pass_through">Pass Through</label>
                  <select
                    name="pass_through"
                    id="pass_through"
                    className="form-select"
                    value={formik.values.pass_through}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  >
                    <option value={false}>False</option>
                    <option value={true}>True</option>
                  </select>

                  {formik.touched.pass_through && formik.errors.pass_through ? (
                    <p className="error_text">{formik.errors.pass_through}</p>
                  ) : null}
                </div>
              </div>

              <div className="col-md-6">
                <div className="mb20px">
                  <label htmlFor="dec_boolean">Active</label>
                  <select
                    name="dec_boolean"
                    id="dec_boolean"
                    className="form-select"
                    value={formik.values.dec_boolean}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  >
                    <option value={!false}>False</option>
                    <option value={!true}>True</option>
                  </select>

                  {formik.touched.dec_boolean && formik.errors.dec_boolean ? (
                    <p className="error_text">{formik.errors.dec_boolean}</p>
                  ) : null}
                </div>
              </div>
            </div>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => {
                  handleClose();
                  formik.resetForm();
                }}
              >
                Cancel
              </Button>
              <Button type="submit">Save</Button>
            </Modal.Footer>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Ruledatabase;
